import React, { useEffect, useRef } from "react";
import toast from "react-hot-toast";
import validator from "validator";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";

import InputPhone from "../input-phone";
import { createContact, getContact, updateContact } from "../../client/methods";
import { responseToastMsg } from "../../utils/response-message";
import { userSchema } from "../../utils/validation-schema";
import { FaArrowLeft, FaSave } from "react-icons/fa";

import "./style.scss";

const ContactForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const init = {
    name: "",
    email: "",
    phone_number: "",
    country_code: "",
  };

  const stateRef = useRef();

  const onSubmit = async (values) => {
    const { name, email } = values;

    if (name && email) {
      if (!validator.isEmail(email)) {
        toast.error("Enter a valid email id");
        return false;
      }

      try {
        if (id && id !== "new") {
          const result = await updateContact(id, values);

          responseToastMsg(result.data.response_code);
        } else {
          const result = await createContact(values);

          responseToastMsg(result.data.response_code);
        }
        navigate("/contacts");
      } catch (error) {
        responseToastMsg(error.data.error_code);
        console.log(
          "🚀 ~ file: index.js ~ line 59 ~ handleSave ~ error",
          error
        );
      }
    } else {
      toast.error("Fill all the mandatory * fields");
    }
  };

  const formik = useFormik({
    initialValues: init,
    validationSchema: userSchema,
    onSubmit,
  });

  stateRef.current = formik.values;

  useEffect(() => {
    if (id === "new") {
      formik.setValues(init);
    } else {
      id && handleGetUser(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleGetUser = async (input) => {
    try {
      const result = await getContact(input);

      formik.setValues({ ...result.data.response });
    } catch (error) {
      responseToastMsg(error.data.error_code);
      console.log(
        "🚀 ~ file: index.js ~ line 36 ~ handleGetUser ~ error",
        error
      );
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="pb-2 sticky-top mb-2 bg-white bottom-shadow">
          <Link to={"/contacts"} className="btn btn-dark btn-sm ps-3 pe-3 me-2">
            <FaArrowLeft /> Back to Contact List
          </Link>
          <button
            type="submit"
            className="btn btn-success btn-sm ps-3 pe-3 me-2"
          >
            <FaSave /> {id === "new" ? "Save" : "Update"}
          </button>
        </div>
        <div className="container-fluid">
          <h6> Personal info </h6>
          <div className="row">
            <div className="col-md-4">
              <div className="mb-2">
                <label>Name*</label>
                <Form.Control
                  type="text"
                  placeholder="First Name - Last Name"
                  size="sm"
                  value={formik.values.name}
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={
                    formik.touched.name && formik.errors.name
                      ? "is-invalid"
                      : !formik.errors.name && "is-valid"
                  }
                />
                {formik.errors.name && formik.touched.name && (
                  <p className="text-danger">{formik.errors.name}</p>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-2">
                <label>Email*</label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  size="sm"
                  value={formik.values.email}
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={
                    formik.touched.email && formik.errors.email
                      ? "is-invalid"
                      : !formik.errors.email && "is-valid"
                  }
                />
                {formik.errors.email && formik.touched.email && (
                  <p className="text-danger">{formik.errors.email}</p>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-2">
                <label>Phone*</label>
                <InputPhone
                  className="form-control"
                  defaultCountry={"us"}
                  value={formik.values.phone_number}
                  onChange={(e, c_code) => {
                    formik.handleChange("phone_number")(e);
                    formik.handleChange("country_code")(
                      c_code.countryCode.toUpperCase()
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ContactForm;
