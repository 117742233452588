import dayjs from "dayjs";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from "libphonenumber-js";

export const validateName = (name) => {
  const re =
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
  return re.test(name);
};

export const dateFormat = (input, format = "DD MMM YYYY") =>
  dayjs(input).format(format);

export const validateNameReplace = (input) =>
  input
    .replace("  ", " ")
    .replace("--", "-")
    .replace(",,", ",")
    .replace("..", ".")
    .replace("''", "'")
    .replace("-,", "-")
    .replace("-.", "-")
    .replace("-'", "-")
    .replace(",-", ",")
    .replace(",.", ",")
    .replace(",'", ",")
    .replace(".-", ".")
    .replace(".,", ".")
    .replace(".'", ".")
    .replace("'-", "'")
    .replace("',", "'")
    .replace("'.", "'");

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePhone = (mobile) => {
  const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im; // eslint-disable-line
  return re.test(mobile);
};

export const validatePAN = (pan) => {
  const re = /^[a-zA-Z0-9]{0,10}$/; // eslint-disable-line
  return re.test(pan);
};

export const validateGSTIN = (gst) => {
  const re = /^[a-zA-Z0-9]{0,15}$/; // eslint-disable-line
  return re.test(gst);
};

export const validateNumber = (value) => {
  const re = /^[1-9][0-9]*$/;
  return re.test(value);
};

export const validatePassword = (password) => {
  const re = /^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/;
  const sp_re = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
  return re.test(password) || sp_re.test(password);
};

export const validateURL = (url) => {
  const re =
    /^http(s?):\/\/(www\.)?(((\w+(([\.\-]{1}([a-z]{2,})+)+)(\/[a-zA-Z0-9\_\=\?\&\.\#\-\W]*)*$)|(\w+((\.([a-z]{2,})+)+)(\:[0-9]{1,5}(\/[a-zA-Z0-9\_\=\?\&\.\#\-\W]*)*$)))|(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}(([0-9]|([1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]+)+)(\/[a-zA-Z0-9\_\=\?\&\.\#\-\W]*)*)((\:[0-9]{1,5}(\/[a-zA-Z0-9\_\=\?\&\.\#\-\W]*)*$)*))$/; // eslint-disable-line
  // /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
  return re.test(url);
};

export const openWindow = (url) => {
  window.open(url, "_self");
};

export const openWindowBlank = (url) => {
  window.open(url, "_blank");
};

export const validateCurrency = (value) => {
  // const re = /^(\d*)\.?(\d){0,10}$/;
  const re = /^\d*\.?\d{0,2}$/;
  return re.test(value);
};

export const passwordLength = 6;

export const crispStyle = {
  container: (prop) => ({
    ...prop,
  }),
  control: (prop, { isFocused, isDisabled }) => ({
    ...prop,
    alignItems: "flex-start",
    borderRadius: "2px",
    minHeight: "30px",
    borderColor: isFocused ? "#3a3a3a" : "#ced4da",
    boxShadow: isFocused ? "0 0 0 0.2rem #44444440" : "0 !important",
    fontColor: "#675d5a",
    "&:hover": {
      // borderColor: "#ced4da",
    },
    "&:focus": {
      // borderColor: "#222",
    },
  }),
  input: (prop) => ({
    ...prop,
    margin: 0,
    padding: "0",
    fontSize: ".75rem",
  }),
  singleValue: (prop) => ({
    ...prop,
    margin: 0,
    padding: 0,
    fontSize: ".75rem",
    color: "#495057",
  }),
  indicatorsContainer: (prop) => ({
    ...prop,
    margin: 0,
    padding: 0,
  }),

  dropdownIndicator: (prop) => ({
    ...prop,
  }),
  indicatorSeparator: (prop) => ({
    ...prop,
    backgroundColor: "#dee2e6",
  }),
  clearIndicator: (prop) => ({
    ...prop,
  }),
  valueContainer: (prop, { isMulti }) => ({
    ...prop,
    minHeight: "30px",
    padding: isMulti ? "0 10px" : "9.2px",
  }),
  option: (prop, { isSelected, isFocused }) => ({
    ...prop,
    padding: "12px",
    fontSize: ".75rem",
    backgroundColor: isSelected ? "#343a40" : isFocused && "#ced4da",
    "&:hover": {
      backgroundColor: isSelected ? "#343a40" : "#ced4da",
    },
  }),
  noOptionsMessage: (prop) => ({
    ...prop,
    fontSize: ".75rem",
  }),
  placeholder: (prop) => ({
    ...prop,
    fontSize: ".75rem",
  }),
  menu: (prop) => ({
    ...prop,
    borderRadius: "3px",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999, top: base.top - 5 }),
};

export const list_size = 10;

export const validInternationalPhone = (input, country) => {
  return (
    isPossiblePhoneNumber(input, country) === true &&
    isValidPhoneNumber(input, country) === true &&
    validatePhoneNumberLength(input, country) === undefined
  );
};

export const currencyFormat = (value, type = "USD") => {
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: type,
  });
  return formatter.format(parseFloat(value ? value : 0));
};

export const handleSelectAll = (event) => event.target.select();

export const numberFormatter = (num, digits = 1, currency = "USD") => {
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  });
  const symb = formatter.format(1).replace("1.00", "");

  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: " Million" },
    { value: 1e9, symbol: " Billion" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return (
    symb +
    (item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0")
  );
};
