import React from "react";
import { useParams } from "react-router-dom";

import UsersList from "./list";
import UserForm from "./form";

import "./style.scss";

const Users = () => {
  const { id } = useParams();

  return <>{id ? <UserForm /> : <UsersList />}</>;
};

export default Users;
