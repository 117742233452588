import React, { useCallback } from "react";
import request from "superagent";
import { useDropzone } from "react-dropzone";
import { FiUploadCloud } from "react-icons/fi";

import { createDocument } from "../../client/methods";
import { responseToastMsg } from "../../utils/response-message";

import "./style.scss";

const DocumentUpload = ({
  maxSize = 100000000000,
  maxFiles = 100,
  callback = () => {},
  documentType,
  dependsTo,
  docs,
}) => {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.length > 0 && handleUpload(acceptedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      accept: {
        "image/*": [".jpeg", ".png"],
        "application/pdf": [".pdf"],
      },
      maxSize,
      maxFiles,
    });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul className="text-danger">
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  const handleUpload = async (input) => {
    const media_urls = [];
    const url = process.env.REACT_APP_CLOUDINARY_URL;
    var cc = 0,
      tcc = input.length;
    for (let file of input) {
      cc++;
      document.body.classList.add("loading-indicator");
      document.getElementById("SpnUpload").innerHTML =
        "Uploading " + cc + "/" + tcc;
      try {
        const response_x = await request
          .post(url)
          .field(
            "upload_preset",
            process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
          )
          .field("file", file)
          .field("folder", process.env.REACT_APP_CLOUDINARY_FOLDER)
          .field("multiple", true)
          .field("quality", process.env.REACT_APP_CLOUDINARY_QUALITY)
          .field("context", "photo=alamo-funding");

        media_urls.push({
          name: file.path,
          type: response_x.body.resource_type,
          public_id: response_x.body.public_id,
          url: response_x.body.secure_url,
        });
        document.body.classList.remove("loading-indicator");
      } catch (err) {
        document.body.classList.remove("loading-indicator");
        console.log(err);
      }
    }
    document.getElementById("SpnUpload").innerHTML = "";
    handleCreateDocument(media_urls);
  };

  const handleCreateDocument = async (input = []) => {
    try {
      var api_input = input.map((xx) => ({
        document: xx,
        document_type: documentType,
        depends_to: dependsTo,
      }));

      const result = await createDocument(api_input);

      responseToastMsg(result.data.response_code);
      callback(
        result.data.response.map((doc) => doc._id),
        docs
      );
    } catch (error) {
      console.log(
        "🚀 ~ file: index.js:84 ~ handleCreateDocument ~ error",
        error
      );
      responseToastMsg(error.data.error_code);
    }
  };

  return (
    <div className="mb-2">
      <div className="file-upload" {...getRootProps()}>
        <input {...getInputProps()} />
        <div className="upload-icon">
          <FiUploadCloud size={40} />
        </div>
        <div className="text-center w-100 mt-2">
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>
              <b>PDF, PNG, or JEPG smaller than 10MB(10,000,000 Bytes)</b>{" "}
              <br /> Drag 'n' drop some files here, or click here to choose
              files {fileRejectionItems}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentUpload;
