import React, { useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import { FiSend } from "react-icons/fi";
import { Link, useNavigate, useParams } from "react-router-dom";

import { createEmail, getEmail } from "../../client/methods";
import { responseToastMsg } from "../../utils/response-message";
import { emailSchema } from "../../utils/validation-schema";
import { FaArrowLeft } from "react-icons/fa";

import "./style.scss";

const EmailForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const init = {
    from: "admin@onclouderp.com",
    to: [],
    cc: [],
    bcc: [],
    subject: "",
    message: "",
  };

  const stateRef = useRef();

  const onSubmit = async (values) => {
    console.log("🚀 ~ file: form.js:31 ~ onSubmit ~ values", values);
    const { to, subject, body } = values;

    if (to && subject && body) {
      try {
        const result = await createEmail(values);

        responseToastMsg(result.data.response_code);
        navigate("/emails");
      } catch (error) {
        responseToastMsg(error.data.error_code);
        console.log(
          "🚀 ~ file: index.js ~ line 59 ~ handleSave ~ error",
          error
        );
      }
    } else {
      toast.error("Fill all the mandatory * fields");
    }
  };

  const formik = useFormik({
    initialValues: init,
    validationSchema: emailSchema,
    onSubmit,
  });

  stateRef.current = formik.values;

  useEffect(() => {
    if (id === "new") {
      formik.setValues(init);
    } else {
      id && handleGetUser(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleGetUser = async (input) => {
    try {
      const result = await getEmail(input);

      formik.setValues({ ...result.data.response });
    } catch (error) {
      responseToastMsg(error.data.error_code);
      console.log(
        "🚀 ~ file: index.js ~ line 36 ~ handleGetUser ~ error",
        error
      );
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="pb-2 sticky-top mb-2 bg-white bottom-shadow">
          <Link to={"/emails"} className="btn btn-dark btn-sm ps-3 pe-3 me-2">
            <FaArrowLeft /> Back to Sent Email List
          </Link>
          {id === "new" && (
            <button
              type="submit"
              className="btn btn-success btn-sm ps-3 pe-3 me-2"
            >
              <FiSend /> Send
            </button>
          )}
        </div>
        <div className="container-fluid">
          <h6> Personal info </h6>
          <div className="row">
            <div className="col-md-12">
              <div className="mb-2">
                <label>From</label>
                <div>admin@cloudpencils.com</div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-2">
                <label>To*</label>
                <Form.Control
                  type="text"
                  placeholder="test@gmail.com"
                  size="sm"
                  value={formik.values.to}
                  name="to"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-2">
                <label>Cc*</label>
                <Form.Control
                  type="text"
                  placeholder="test@gmail.com"
                  size="sm"
                  value={formik.values.cc}
                  name="cc"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-2">
                <label>Bcc*</label>
                <Form.Control
                  type="text"
                  placeholder="test@gmail.com"
                  size="sm"
                  value={formik.values.bcc}
                  name="bcc"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-2">
                <label>Subject*</label>
                <Form.Control
                  type="text"
                  placeholder="Subject for the email"
                  size="sm"
                  value={formik.values.subject}
                  name="subject"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={
                    formik.touched.subject && formik.errors.subject
                      ? "is-invalid"
                      : !formik.errors.subject && "is-valid"
                  }
                />
                {formik.errors.subject && formik.touched.subject && (
                  <p className="text-danger">{formik.errors.subject}</p>
                )}
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-2">
                <label>Message*</label>
                <textarea
                  value={formik.values.message}
                  name="message"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`form-control ${
                    formik.touched.message && formik.errors.message
                      ? "is-invalid"
                      : !formik.errors.message && "is-valid"
                  }`}
                  rows={5}
                ></textarea>
                {formik.errors.message && formik.touched.message && (
                  <p className="text-danger">{formik.errors.message}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default EmailForm;
