import React, { useState, useEffect } from "react";
import { FiDownload, FiTrash2 } from "react-icons/fi";

import { deleteDocument, getMultiDocument } from "../../client/methods";
import { dateFormat } from "../../utils/common";
import { documentTypes } from "../../utils/options";
import { responseToastMsg } from "../../utils/response-message";

const DocumentList = ({ docs = [], deleteCallback = () => {} }) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    docs.length > 0 ? getDocuments(docs) : setList([]);
  }, [docs]);

  const getDocuments = async (input) => {
    try {
      const result = await getMultiDocument(input);

      setList(result.data.response);
    } catch (error) {
      console.log("🚀 ~ file: index.js:16 ~ getDocuments ~ error", error);
    }
  };

  const handleDeleteDocument = async (input) => {
    try {
      const result = await deleteDocument(input);
      deleteCallback(input._id);
      setList(list.filter((xx) => xx._id !== input._id));
      responseToastMsg(result.data.response_code);
    } catch (error) {
      console.log("🚀 ~ file: index.js:16 ~ getDocuments ~ error", error);
    }
  };

  if (list.length > 0)
    return (
      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Uploaded On</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {list.map((obj, i) => (
              <tr key={`doc_list_${i}`}>
                <td>{obj.document?.name}</td>
                <td>
                  {
                    documentTypes.find((o) => o.value === obj.document_type)
                      .label
                  }
                </td>
                <td>{dateFormat(obj.createdAt)}</td>
                <td className="text-end">
                  <a
                    href={obj.document?.url}
                    target="_blank"
                    rel="noreferrer"
                    download
                    className="text-dark"
                  >
                    <FiDownload className="like-btn me-3" size={20} />
                  </a>

                  <FiTrash2
                    className="like-btn"
                    size={20}
                    onClick={() => handleDeleteDocument(obj)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  else return null;
};

export default DocumentList;
