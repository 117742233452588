import React, { useCallback } from "react";
import request from "superagent";
import { useDropzone } from "react-dropzone";
import { FiUploadCloud } from "react-icons/fi";

import "./style.scss";
import { createDocument } from "../../client/methods";
import { responseToastMsg } from "../../utils/response-message";

const BulkUpload = ({
  maxSize = 10485760,
  maxFiles = 100,
  callback = () => {},
  isVideo = false,
  documentType,
  dependsTo,
  docs,
}) => {
  const publicKey = process.env.REACT_APP_IMAGEKIT_PUBLIC_KEY;
  const authenticationEndpoint = process.env.REACT_APP_IMAGEKIT_AUTH_URL;

  const fileSizeMax = isVideo ? 104857600 : maxSize;

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.length > 0 && handleUpload(acceptedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      accept: isVideo
        ? {
            "video/*": [".avi", ".mp4", ".mpeg", ".webm"],
          }
        : {
            "image/*": [".jpeg", ".png"],
            "application/pdf": [".pdf"],
          },
      fileSizeMax,
      maxFiles,
    });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul className="text-danger">
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  const getAuth = async () => {
    const headers = {
      CustomHeader: "CustomValue",
    };

    const response = await fetch(authenticationEndpoint, {
      headers,
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(
        `Request failed with status ${response.status}: ${errorText}`
      );
    }

    const data = await response.json();
    return data;
  };

  const handleUpload = async (input) => {
    const media_urls = [];

    const url = process.env.REACT_APP_IMAGEKIT_UPLOAD_URL;
    var cc = 0,
      tcc = input.length;
    for (let file of input) {
      const { signature, expire, token } = await getAuth();
      cc++;
      document.body.classList.add("loading-indicator");
      document.getElementById("SpnUpload").innerHTML =
        "Uploading " + cc + "/" + tcc;
      try {
        const response_x = await request
          .post(url)
          .field("signature", signature)
          .field("file", file)
          .field("folder", "dev")
          .field("expire", expire)
          .field("token", token)
          .field("publicKey", publicKey)
          .field("fileName", file.name);

        media_urls.push({
          name: file.path,
          type: response_x.body.fileType,
          public_id: response_x.body.fileId,
          url: response_x.body.url,
        });

        // callback(media_urls);

        document.body.classList.remove("loading-indicator");
      } catch (err) {
        document.body.classList.remove("loading-indicator");
        console.log(err);
      }
    }
    document.getElementById("SpnUpload").innerHTML = "";
    handleCreateDocument(media_urls);
  };

  const handleCreateDocument = async (input = []) => {
    try {
      var api_input = input.map((xx) => ({
        document: xx,
        document_type: documentType,
        depends_to: dependsTo,
      }));

      const result = await createDocument(api_input);

      responseToastMsg(result.data.response_code);
      callback(
        result.data.response.map((doc) => doc._id),
        docs
      );
    } catch (error) {
      console.log(
        "🚀 ~ file: index.js:84 ~ handleCreateDocument ~ error",
        error
      );
      responseToastMsg(error.data.error_code);
    }
  };

  return (
    <div className="mb-2">
      <div className="file-upload" {...getRootProps()}>
        <input {...getInputProps()} />
        <div className="upload-icon">
          <FiUploadCloud size={40} />
        </div>
        <div className="text-center w-100 mt-2">
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>
              <b>
                Supports{" "}
                {isVideo ? ".avi, .mp4, .mpeg, .webm" : ".pdf, .png, or .jpg"}{" "}
                smaller than {(fileSizeMax / (1024 * 1024)).toFixed(2)} MB(
                {fileSizeMax} Bytes)
              </b>{" "}
              <br /> Drag 'n' drop some files here, or click here to choose
              files {fileRejectionItems}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BulkUpload;
