import React, { useEffect, useState } from "react";
import { BsTrash } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

import { deleteInvestment, getInvestmentList } from "../../client/methods";
import { list_size } from "../../utils/common";
import { responseToastMsg } from "../../utils/response-message";

const InvestmentList = () => {
  const navigate = useNavigate();

  const [count, setCount] = useState(0);
  const [list, setList] = useState([]);
  const [loadHide, setLoadHide] = useState(false);

  useEffect(() => {
    getList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getList = async () => {
    try {
      const result = await getInvestmentList(list_size, count);

      setList([...list, ...result.data.response]);
      setCount(count + result.data.response.length);
      if (result.data.response.length === 0) {
        setLoadHide(true);
      }
    } catch (error) {
      responseToastMsg(error.data.error_code);
    }
  };

  const handleDelete = (e, input) => {
    e.preventDefault();
    e.stopPropagation();

    if (window.confirm("Are you sure to delete?")) {
      deleteProcess(input);
    } else {
      return false;
    }
  };

  const deleteProcess = async (input) => {
    try {
      const result = await deleteInvestment(input);
      responseToastMsg(result.data.response_code);
      handleAction({ type: "delete", data: input });
    } catch (error) {
      responseToastMsg(error.data.error_code);
    }
  };

  const handleAction = ({ type, data }) => {
    if (type === "save") {
      const temp = [...list];
      temp.push(data);
      setList(temp);
    } else if (type === "update") {
      const temp = [...list];
      const index = temp.findIndex((o) => o._id === data._id);
      temp[index] = data;
      setList(temp);
    } else if (type === "delete") {
      setList(list.filter((o) => o._id !== data));
    }
  };

  return (
    <>
      <div className="content-view">
        <div className="pb-2 sticky-top mb-2 bg-color-default">
          <Link
            to={"/investments/new"}
            className="btn btn-dark btn-sm ps-4 pe-4"
          >
            <FaPlus /> New Investment
          </Link>
        </div>

        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th> Investor Name </th>
                <th> Property </th>
                <th> Amount </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {list.map((obj, i) => (
                <tr
                  key={`ulist${i}`}
                  onClick={() => navigate(`/investments/${obj._id}`)}
                  className="cursor-pointer"
                >
                  <td>{obj.investor}</td>
                  <td>{obj.property}</td>
                  <td>{obj.amount}</td>

                  <td>
                    <button
                      type="button"
                      disabled={obj.is_admin}
                      className="btn btn-danger btn-sm"
                      onClick={(e) => !obj.is_admin && handleDelete(e, obj._id)}
                    >
                      <BsTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {!loadHide && (
            <div className="text-center mt-2">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={() => getList()}
              >
                Load more
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default InvestmentList;
