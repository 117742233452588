import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import toast from "react-hot-toast";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import DatePicker from "react-datepicker";
import validator from "validator";
import Toggle from "react-toggle";
import InputMask from "react-input-mask";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import { Link, useParams, useNavigate } from "react-router-dom";

import ImageInput from "../image-input/index";
import countries from "../../utils/countries.json";
import InputPhone from "../input-phone/index";
import DocumentUpload from "../document-upload";
import DocumentList from "../document-list";
import {
  createInvestor,
  getInvestor,
  updateInvestor,
} from "../../client/methods";
import { responseToastMsg } from "../../utils/response-message";
import { crispStyle } from "../../utils/common";
import { investorSchema } from "../../utils/validation-schema";
import { FaArrowLeft, FaSave } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import InvestingAccounts from "../investing-accounts";
import Modal from "react-bootstrap/Modal";
import {
  residenceTypeOptions,
  maritalStatusOptions,
  industryOptions,
  regulatoryTypesOptions,
  propertyTypesOptions,
  regionOptions,
  marketSizeOptions,
  vechilesOptions,
  holdPeriodOptions,
  objectivesOptions,
  documentTypeIds,
  documentDependIds,
  investmentInTwelveMonthsOptions,
  investmentAmountPerProjectOptions,
  riskToleranceOptions,
} from "../../utils/options";

import "./style.scss";
import InvestingAccountsList from "../investing-accounts/list";

const InvestorForm = () => {
  const navigate = useNavigate();
  const { id, aid } = useParams();
  const [show, setShow] = useState(false);

  const formRef = useRef();

  const init = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    self_accredited: false,
    phone_number: "",
    country_code: "",
    investor_image: "",
    ssn: "",
    currency_name: "USD",
    currency_symbol: "$",

    residence_status: "citizen",
    marital_status: "single",
    country_of_citizenship: "USA",
    annual_income: "1",
    dob: null,

    personal_address: {
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      country: "USA",
      postal_code: "",
    },

    employer_information: {
      occupation: "",
      employer_name: "",
      industry: "no_industry_selected",
      address: {
        address_line_1: "",
        address_line_2: "",
        city: "",
        state: "",
        country: "USA",
        postal_code: "",
      },
    },
    regulatory_agency_relation: false,
    regulatory_agency_relation_types: [],
    regulatory_agency_relation_docs: [],
    investment_interests: {
      property_types: [],
      region: [],
      market_size: [],
      hold_period: [],
      vehicles: [],
      objectives: [],
    },
    investment_objectives: {
      investment_in_twelve_months: "undecided",
      investment_amount_per_project: "undecided",
      risk_tolerance: "undecided",
    },
    security: {
      is_email_verify: false,
    },
    investor_documents: [],
    govt_docs: [],
    is_active: true,
    orgin: "web",
  };

  const onSubmit = async (values) => {
    const { first_name, last_name, email } = values;

    if (first_name && last_name && email) {
      if (!validator.isEmail(email)) {
        toast.error("Enter a valid email id");
        return false;
      }

      try {
        if (id && id !== "new") {
          const result = await updateInvestor(id, values);

          responseToastMsg(result.data.response_code);
        } else {
          const result = await createInvestor(values);

          responseToastMsg(result.data.response_code);
        }
      } catch (error) {
        responseToastMsg(error.data.error_code);
        console.log(
          "🚀 ~ file: index.js ~ line 59 ~ handleSave ~ error",
          error
        );
      }
    } else {
      toast.error("Fill all the mandatory * fields");
    }
  };

  const formik = useFormik({
    initialValues: init,
    validationSchema: investorSchema,
    onSubmit,
  });

  formRef.current = formik.values;

  useEffect(() => {
    if (id === "new") {
      formik.setValues(init);
    } else {
      id && handleGetUser(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (aid) setShow(true);
    else setShow(false);
  }, [aid]);

  const handleGetUser = async (input) => {
    try {
      const result = await getInvestor(input);

      formik.setValues({ ...result.data.response });
    } catch (error) {
      responseToastMsg(error.data.error_code);
      console.log(
        "🚀 ~ file: index.js ~ line 36 ~ handleGetUser ~ error",
        error
      );
    }
  };

  const handleImageChange = (input) => {
    formik.setFieldValue("investor_image", input);
  };

  const handleRegulatoryTypes = (e, input) => {
    if (e.target.checked) {
      formik.setFieldValue("regulatory_agency_relation_types", [
        ...formik.values.regulatory_agency_relation_types,
        input,
      ]);
    } else {
      formik.setFieldValue(
        "regulatory_agency_relation_types",
        formik.values.regulatory_agency_relation_types.filter(
          (o) => o !== input
        )
      );
    }
  };

  const handlePropertyTypes = (e, input) => {
    if (e.target.checked) {
      formik.setFieldValue("investment_interests.property_types", [
        ...formik.values?.investment_interests?.property_types,
        input,
      ]);
    } else {
      formik.setFieldValue(
        "investment_interests.property_types",
        formik.values?.investment_interests?.property_types.filter(
          (o) => o !== input
        )
      );
    }
  };

  const handleRegion = (e, input) => {
    if (e.target.checked) {
      formik.setFieldValue("investment_interests.region", [
        ...formik.values?.investment_interests?.region,
        input,
      ]);
    } else {
      formik.setFieldValue(
        "investment_interests.region",
        formik.values?.investment_interests?.region.filter((o) => o !== input)
      );
    }
  };

  const handleMarketSize = (e, input) => {
    if (e.target.checked) {
      formik.setFieldValue("investment_interests.market_size", [
        ...formik.values?.investment_interests?.market_size,
        input,
      ]);
    } else {
      formik.setFieldValue(
        "investment_interests.market_size",
        formik.values?.investment_interests?.market_size.filter(
          (o) => o !== input
        )
      );
    }
  };

  const handleVehicles = (e, input) => {
    if (e.target.checked) {
      formik.setFieldValue("investment_interests.vehicles", [
        ...formik.values?.investment_interests?.vehicles,
        input,
      ]);
    } else {
      formik.setFieldValue(
        "investment_interests.vehicles",
        formik.values?.investment_interests?.vehicles.filter((o) => o !== input)
      );
    }
  };

  const handleHoldPeriod = (e, input) => {
    if (e.target.checked) {
      formik.setFieldValue("investment_interests.hold_period", [
        ...formik.values?.investment_interests?.hold_period,
        input,
      ]);
    } else {
      formik.setFieldValue(
        "investment_interests.hold_period",
        formik.values?.investment_interests?.hold_period.filter(
          (o) => o !== input
        )
      );
    }
  };

  const handleObjectives = (e, input) => {
    if (e.target.checked) {
      formik.setFieldValue("investment_interests.objectives", [
        ...formik.values?.investment_interests?.objectives,
        input,
      ]);
    } else {
      formik.setFieldValue(
        "investment_interests.objectives",
        formik.values?.investment_interests?.objectives.filter(
          (o) => o !== input
        )
      );
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div
          className="pb-2 sticky-top mb-2 bg-white bottom-shadow"
          style={{ zIndex: 1 }}
        >
          <Link
            to={"/investors"}
            className="btn btn-dark btn-sm ps-3 pe-3 me-2"
          >
            <FaArrowLeft /> Back to Investors List
          </Link>
          <button className="btn btn-success btn-sm ps-3 pe-3 me-2">
            <FaSave /> {id === "new" ? "Save" : "Update"}
          </button>
        </div>
        <div className="container-fluid">
          <Tabs defaultActiveKey="profile" className="mb-3">
            <Tab eventKey="profile" title="Profile">
              <>
                <div className="part-section">
                  <h6> Personal Informations </h6>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="mb-2">
                        <label>Profile Photo</label> <br />
                        <ImageInput
                          source={formik.values.investor_image}
                          onChangeImage={handleImageChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-2">
                            <label>First Name*</label>
                            <Form.Control
                              type="text"
                              placeholder="First Name"
                              size="sm"
                              value={formik.values.first_name}
                              name="first_name"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className={
                                formik.touched.first_name &&
                                formik.errors.first_name
                                  ? "is-invalid"
                                  : !formik.errors.first_name && "is-valid"
                              }
                            />
                            {formik.errors.first_name &&
                              formik.touched.first_name && (
                                <p className="text-danger">
                                  {formik.errors.first_name}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-2">
                            <label>Last Name*</label>
                            <Form.Control
                              type="text"
                              placeholder="Last Name"
                              size="sm"
                              value={formik.values.last_name}
                              name="last_name"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className={
                                formik.touched.last_name &&
                                formik.errors.last_name
                                  ? "is-invalid"
                                  : !formik.errors.last_name && "is-valid"
                              }
                            />
                            {formik.errors.last_name &&
                              formik.touched.last_name && (
                                <p className="text-danger">
                                  {formik.errors.last_name}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-2">
                            <label>Middle Name</label>
                            <Form.Control
                              type="text"
                              placeholder="Middle Name"
                              size="sm"
                              value={formik.values.middle_name}
                              name="middle_name"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-2">
                            <label>Email*</label>
                            <Form.Control
                              type="email"
                              placeholder="Email"
                              size="sm"
                              value={formik.values.email}
                              name="email"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className={
                                formik.touched.email && formik.errors.email
                                  ? "is-invalid"
                                  : !formik.errors.email && "is-valid"
                              }
                            />
                            {formik.errors.email && formik.touched.email && (
                              <p className="text-danger">
                                {formik.errors.email}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-2">
                            <label>SSN*</label>
                            <InputMask
                              className="form-control"
                              mask="999-99-9999"
                              maskChar="_"
                              value={formik.values.ssn}
                              name="ssn"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />

                            {formik.errors.email && formik.touched.email && (
                              <p className="text-danger">
                                {formik.errors.email}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-2">
                            <label>Residence Status</label>
                            <Select
                              menuPosition={"fixed"}
                              value={
                                formik.values?.residence_status
                                  ? residenceTypeOptions.find(
                                      (o) =>
                                        o.value ===
                                        formik.values.residence_status
                                    )
                                  : null
                              }
                              styles={crispStyle}
                              options={residenceTypeOptions}
                              onChange={(data) =>
                                formik.handleChange("residence_status")(
                                  data.value
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-2">
                            <label>Marital Status</label>
                            <Select
                              menuPosition={"fixed"}
                              value={
                                formik.values?.marital_status
                                  ? maritalStatusOptions.find(
                                      (o) =>
                                        o.value === formik.values.marital_status
                                    )
                                  : null
                              }
                              styles={crispStyle}
                              options={maritalStatusOptions}
                              onChange={(data) =>
                                formik.handleChange("marital_status")(
                                  data.value
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-2">
                            <label>Country of Citizenship</label>
                            <Select
                              menuPosition={"fixed"}
                              value={
                                formik.values?.country_of_citizenship
                                  ? countries
                                      .map((o) => ({
                                        label: o.name,
                                        value: o.code3,
                                      }))
                                      .find(
                                        (o) =>
                                          o.value ===
                                          formik.values.country_of_citizenship
                                      )
                                  : null
                              }
                              styles={crispStyle}
                              options={countries.map((o) => ({
                                label: o.name,
                                value: o.code3,
                              }))}
                              onChange={(data) =>
                                formik.handleChange("country_of_citizenship")(
                                  data.value
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-2">
                            <label>Mobile Number</label>
                            <InputPhone
                              className="form-control"
                              defaultCountry={"us"}
                              value={formik.values.phone_number}
                              onChange={(e, c_code) => {
                                formik.handleChange("phone_number")(e);
                                formik.handleChange("country_code")(
                                  c_code.countryCode.toUpperCase()
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="mb-2">
                            <label>Date of birth</label>
                            <DatePicker
                              className="form-control"
                              dateFormat={"MMM dd yyyy"}
                              selected={
                                formik.values.dob && new Date(formik.values.dob)
                              }
                              onChange={(date) =>
                                formik.setFieldValue("dob", date)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="mb-2">
                            <label>Active</label> <br />
                            <Toggle
                              className="mt-2"
                              checked={formik.values.is_active}
                              name="is_active"
                              onChange={formik.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="mb-2">
                            <label>
                              Are you an accredited investor? - Self
                              Accreditation
                            </label>
                            <br />
                            <Toggle
                              className="mt-2"
                              checked={formik.values.self_accredited}
                              name="self_accredited"
                              onChange={formik.handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="part-section">
                  <h6> Personal address </h6>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="mb-2">
                            <label>Address Line 1*</label>
                            <Form.Control
                              type="text"
                              placeholder="Address Line 1"
                              size="sm"
                              value={
                                formik?.values?.personal_address?.address_line_1
                              }
                              name="personal_address.address_line_1"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className={
                                formik.touched.address_line_1 &&
                                formik.errors.address_line_1
                                  ? "is-invalid"
                                  : !formik.errors.address_line_1 && "is-valid"
                              }
                            />
                            {formik.errors.address_line_1 &&
                              formik.touched.address_line_1 && (
                                <p className="text-danger">
                                  {formik.errors.address_line_1}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-2">
                            <label>Address Line 2*</label>
                            <Form.Control
                              type="text"
                              placeholder="Address Line 2"
                              size="sm"
                              value={
                                formik?.values?.personal_address?.address_line_2
                              }
                              name="personal_address.address_line_2"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-2">
                            <label>City</label>
                            <Form.Control
                              type="text"
                              placeholder="City"
                              size="sm"
                              value={formik?.values?.personal_address?.city}
                              name="personal_address.city"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-2">
                            <label>State</label>
                            <Form.Control
                              type="state"
                              placeholder="state"
                              size="sm"
                              value={formik?.values?.personal_address?.state}
                              name="personal_address.state"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-2">
                            <label>Country</label>
                            <Select
                              menuPosition={"fixed"}
                              value={
                                formik.values?.personal_address?.country
                                  ? countries
                                      .map((o) => ({
                                        label: o.name,
                                        value: o.code3,
                                      }))
                                      .find(
                                        (o) =>
                                          o.value ===
                                          formik.values?.personal_address
                                            ?.country
                                      )
                                  : null
                              }
                              styles={crispStyle}
                              options={countries.map((o) => ({
                                label: o.name,
                                value: o.code3,
                              }))}
                              onChange={(data) =>
                                formik.handleChange("personal_address.country")(
                                  data.value
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-2">
                            <label>Postal Code</label>
                            <Form.Control
                              type="postal_code"
                              placeholder="Postal Code"
                              size="sm"
                              value={
                                formik?.values?.personal_address?.postal_code
                              }
                              name="personal_address.postal_code"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="part-section">
                  <h6> Professional Information </h6>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="mb-2">
                            <label>Occupation</label>
                            <Form.Control
                              type="text"
                              placeholder="Occupation"
                              size="sm"
                              value={
                                formik?.values?.employer_information?.occupation
                              }
                              name="employer_information.occupation"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-2">
                            <label>Employer Name</label>
                            <Form.Control
                              type="text"
                              placeholder="Employer Name"
                              size="sm"
                              value={
                                formik?.values?.employer_information
                                  ?.employer_name
                              }
                              name="employer_information.employer_name"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-2">
                            <label>Industry</label>
                            <Select
                              menuPosition={"fixed"}
                              value={
                                formik.values?.employer_information?.industry
                                  ? industryOptions.find(
                                      (o) =>
                                        o.value ===
                                        formik.values?.employer_information
                                          ?.industry
                                    )
                                  : null
                              }
                              styles={crispStyle}
                              options={industryOptions}
                              onChange={(data) =>
                                formik.handleChange(
                                  "employer_information.industry"
                                )(data.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="part-section">
                  <h6> Employer Address </h6>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="mb-2">
                            <label>Address Line 1*</label>
                            <Form.Control
                              type="text"
                              placeholder="Address Line 1"
                              size="sm"
                              value={
                                formik?.values?.employer_information?.address
                                  ?.address_line_1
                              }
                              name="employer_information.address.address_line_1"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className={
                                formik.touched.address_line_1 &&
                                formik.errors.address_line_1
                                  ? "is-invalid"
                                  : !formik.errors.address_line_1 && "is-valid"
                              }
                            />
                            {formik.errors.address_line_1 &&
                              formik.touched.address_line_1 && (
                                <p className="text-danger">
                                  {formik.errors.address_line_1}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-2">
                            <label>Address Line 2*</label>
                            <Form.Control
                              type="text"
                              placeholder="Address Line 2"
                              size="sm"
                              value={
                                formik?.values?.employer_information?.address
                                  ?.address_line_2
                              }
                              name="employer_information.address.address_line_2"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-2">
                            <label>City</label>
                            <Form.Control
                              type="text"
                              placeholder="City"
                              size="sm"
                              value={
                                formik?.values?.employer_information?.address
                                  ?.city
                              }
                              name="employer_information.address.city"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-2">
                            <label>State</label>
                            <Form.Control
                              type="state"
                              placeholder="state"
                              size="sm"
                              value={
                                formik?.values?.employer_information?.address
                                  ?.state
                              }
                              name="employer_information.address.state"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-2">
                            <label>Country</label>
                            <Select
                              menuPosition={"fixed"}
                              value={
                                formik.values?.employer_information?.address
                                  ?.country
                                  ? countries
                                      .map((o) => ({
                                        label: o.name,
                                        value: o.code3,
                                      }))
                                      .find(
                                        (o) =>
                                          o.value ===
                                          formik.values?.employer_information
                                            ?.address?.country
                                      )
                                  : null
                              }
                              styles={crispStyle}
                              options={countries.map((o) => ({
                                label: o.name,
                                value: o.code3,
                              }))}
                              onChange={(data) =>
                                formik.handleChange(
                                  "employer_information.address.country"
                                )(data.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-2">
                            <label>Postal Code</label>
                            <Form.Control
                              type="postal_code"
                              placeholder="Postal Code"
                              size="sm"
                              value={
                                formik?.values?.employer_information?.address
                                  ?.postal_code
                              }
                              name="employer_information.address.postal_code"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="part-section">
                  <div className="mb-2">
                    <h6>
                      Are you, your spouse, or any other dependents employed by,
                      or associated with, a broker-dealer firm or a financial
                      services regulatory agency?
                    </h6>
                    <Toggle
                      className="mt-2"
                      checked={formik.values.regulatory_agency_relation}
                      name="regulatory_agency_relation"
                      onChange={formik.handleChange}
                    />
                  </div>
                  {formik.values.regulatory_agency_relation && (
                    <>
                      <div className="mb-2">
                        <h6>
                          How are you, your spouse, or any other dependents
                          employed by, or associated with, the securities
                          industry or financial services regulator?
                        </h6>
                        {regulatoryTypesOptions.map((obj, i) => (
                          <div key={`reg${i}`} className="check-list">
                            <label>
                              <input
                                type="checkbox"
                                checked={formik.values.regulatory_agency_relation_types.includes(
                                  obj.value
                                )}
                                onChange={(e) =>
                                  handleRegulatoryTypes(e, obj.value)
                                }
                              />{" "}
                              {obj.label}
                            </label>
                          </div>
                        ))}
                      </div>
                      <div className="mb-2">
                        <h6> Association authorization letter </h6>
                        <DocumentUpload
                          callback={(input) => {
                            formik.setFieldValue(
                              "regulatory_agency_relation_docs",
                              [
                                ...formRef.current
                                  .regulatory_agency_relation_docs,
                                ...input,
                              ]
                            );
                          }}
                          documentType={documentTypeIds.regulatory}
                          dependsTo={documentDependIds.investor}
                        />
                        <DocumentList
                          docs={formik.values.regulatory_agency_relation_docs}
                          deleteCallback={(_data) => {
                            formik.setFieldValue(
                              "regulatory_agency_relation_docs",
                              formik.values.regulatory_agency_relation_docs.filter(
                                (xx) => xx !== _data
                              )
                            );
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="part-section">
                  <div className="mb-2">
                    <h6> Government-issued photo ID * </h6>
                    <DocumentUpload
                      callback={(input) => {
                        formik.setFieldValue("govt_docs", [
                          ...formRef.current.govt_docs,
                          ...input,
                        ]);
                      }}
                      documentType={documentTypeIds.government}
                      dependsTo={documentDependIds.investor}
                    />
                    <DocumentList
                      docs={formik.values.govt_docs}
                      deleteCallback={(_data) => {
                        formik.setFieldValue(
                          "govt_docs",
                          formik.values.govt_docs.filter((xx) => xx !== _data)
                        );
                      }}
                    />
                  </div>
                </div>
              </>
            </Tab>
            <Tab eventKey="preference" title="Investment Preference">
              <div className="part-section">
                <h5> Investment Interests </h5>
                <>
                  <div className="mb-2">
                    <div className="row">
                      <div className="col-3">
                        <h6>Property Types</h6>
                        {propertyTypesOptions.map((obj, i) => (
                          <div key={`reg${i}`} className="check-list">
                            <label>
                              <input
                                type="checkbox"
                                checked={formik.values?.investment_interests?.property_types.includes(
                                  obj.value
                                )}
                                onChange={(e) =>
                                  handlePropertyTypes(e, obj.value)
                                }
                              />{" "}
                              {obj.label}
                            </label>
                          </div>
                        ))}
                      </div>
                      <div className="col-3">
                        <h6>Region</h6>
                        {regionOptions.map((obj, i) => (
                          <div key={`reg${i}`} className="check-list">
                            <label>
                              <input
                                type="checkbox"
                                checked={formik.values?.investment_interests?.region.includes(
                                  obj.value
                                )}
                                onChange={(e) => handleRegion(e, obj.value)}
                              />{" "}
                              {obj.label}
                            </label>
                          </div>
                        ))}
                      </div>
                      <div className="col-3">
                        <div className="row">
                          <h6>Market size</h6>
                          {marketSizeOptions.map((obj, i) => (
                            <div key={`reg${i}`} className="check-list">
                              <label>
                                <input
                                  type="checkbox"
                                  checked={formik.values?.investment_interests?.market_size.includes(
                                    obj.value
                                  )}
                                  onChange={(e) =>
                                    handleMarketSize(e, obj.value)
                                  }
                                />{" "}
                                {obj.label}
                              </label>
                            </div>
                          ))}
                        </div>
                        <div className="row">
                          <h6>Vehicles</h6>
                          {vechilesOptions.map((obj, i) => (
                            <div key={`reg${i}`} className="check-list">
                              <label>
                                <input
                                  type="checkbox"
                                  checked={formik.values?.investment_interests?.vehicles.includes(
                                    obj.value
                                  )}
                                  onChange={(e) => handleVehicles(e, obj.value)}
                                />{" "}
                                {obj.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="row">
                          <h6>Hold Period</h6>
                          {holdPeriodOptions.map((obj, i) => (
                            <div key={`reg${i}`} className="check-list">
                              <label>
                                <input
                                  type="checkbox"
                                  checked={formik.values?.investment_interests?.hold_period.includes(
                                    obj.value
                                  )}
                                  onChange={(e) =>
                                    handleHoldPeriod(e, obj.value)
                                  }
                                />{" "}
                                {obj.label}
                              </label>
                            </div>
                          ))}
                        </div>
                        <div className="row">
                          <h6>Objectives</h6>
                          {objectivesOptions.map((obj, i) => (
                            <div key={`reg${i}`} className="check-list">
                              <label>
                                <input
                                  type="checkbox"
                                  checked={formik.values?.investment_interests?.objectives.includes(
                                    obj.value
                                  )}
                                  onChange={(e) =>
                                    handleObjectives(e, obj.value)
                                  }
                                />{" "}
                                {obj.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
              <div className="part-section">
                <div className="mb-2">
                  <h6>Investment Objectives</h6>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-2">
                        <label>
                          Expected Total Investment in Next Twelve Months
                        </label>
                        <Select
                          menuPosition={"fixed"}
                          value={
                            formik.values?.investment_objectives
                              ?.investment_in_twelve_months
                              ? investmentInTwelveMonthsOptions.find(
                                  (o) =>
                                    o.value ===
                                    formik.values?.investment_objectives
                                      ?.investment_in_twelve_months
                                )
                              : null
                          }
                          styles={crispStyle}
                          options={investmentInTwelveMonthsOptions}
                          onChange={(data) =>
                            formik.handleChange(
                              "investment_objectives.investment_in_twelve_months"
                            )(data.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-2">
                        <label>Expected Investment Amount Per Project</label>
                        <Select
                          menuPosition={"fixed"}
                          value={
                            formik.values?.investment_objectives
                              ?.investment_amount_per_project
                              ? investmentAmountPerProjectOptions.find(
                                  (o) =>
                                    o.value ===
                                    formik.values?.investment_objectives
                                      ?.investment_amount_per_project
                                )
                              : null
                          }
                          styles={crispStyle}
                          options={investmentAmountPerProjectOptions}
                          onChange={(data) =>
                            formik.handleChange(
                              "investment_objectives.investment_amount_per_project"
                            )(data.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-2">
                        <label>Risk Tolerance</label>
                        <Select
                          menuPosition={"fixed"}
                          value={
                            formik.values?.investment_objectives?.risk_tolerance
                              ? riskToleranceOptions.find(
                                  (o) =>
                                    o.value ===
                                    formik.values?.investment_objectives
                                      ?.risk_tolerance
                                )
                              : null
                          }
                          styles={crispStyle}
                          options={riskToleranceOptions}
                          onChange={(data) =>
                            formik.handleChange(
                              "investment_objectives.risk_tolerance"
                            )(data.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="investor_docs" title="Investor Documents">
              <div className="part-section">
                <div className="mb-2">
                  <h6> Investor Documents </h6>
                  <DocumentUpload
                    callback={(input) => {
                      formik.setFieldValue("investor_documents", [
                        ...formRef.current?.investor_documents,
                        ...input,
                      ]);
                    }}
                    documentType={documentTypeIds.investor}
                    dependsTo={documentDependIds.investor}
                  />
                  <DocumentList
                    docs={formik.values?.investor_documents}
                    deleteCallback={(_data) => {
                      formik.setFieldValue(
                        "investor_documents",
                        formik.values?.investor_documents.filter(
                          (xx) => xx !== _data
                        )
                      );
                    }}
                  />
                </div>
              </div>
            </Tab>
            <Tab eventKey="security" title="Security">
              <div className="part-section">
                <div className="mb-2">
                  <div className="row">
                    <h5> Security </h5>
                  </div>
                  <div className="row">
                    <h6>Keep your account secure</h6>
                  </div>
                  <div className="row">
                    <div className="col-9">
                      <label>
                        Verify <b>{formik.values?.email}</b>
                      </label>
                    </div>
                    <div className="col-3">
                      <Toggle
                        className="mt-2"
                        checked={formik.values?.security?.is_email_verify}
                        name="security.is_email_verify"
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab
              eventKey="investing_accounts"
              title="Investing Accounts"
              disabled={id === "new"}
            >
              <div className="d-flex justify-content-center align-items-center vh-80 pb-5">
                <div className="part-section">
                  <div className="mb-2">
                    <div className="row">
                      {" "}
                      <h4>
                        Looks like you don't have any Investing Accounts yet.{" "}
                      </h4>
                    </div>
                    <div className="row">
                      {" "}
                      <h6>
                        You can set up an Investing Account to participate in
                        our Marketplace offerings.
                      </h6>
                    </div>
                    <div className="row">
                      <div className="pb-2 sticky-top mb-2 bg-color-default">
                        <Link
                          to={`/investors/${id}/investing-accounts/new`}
                          className="btn btn-dark btn-sm ps-4 pe-4"
                        >
                          <FaPlus /> Add Investing Account
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="part-section">
                  <div className="mb-2">
                    <div className="row">
                      <InvestingAccountsList />
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
        <Modal
          show={show}
          onHide={() => navigate(`/investors/${id}`)}
          size={"xl"}
          backdrop={"static"}
        >
          <Modal.Header closeButton>
            <Modal.Title>{"Individual Accounts "}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0">
            <InvestingAccounts />
          </Modal.Body>
        </Modal>
        {id === "new" && (
          <div className="fs-little">
            Note: Investor created from Admin side will have the default
            password "welcome123" for login in to their account
          </div>
        )}
      </form>
    </>
  );
};

export default InvestorForm;
