import React, { useState, useEffect } from "react";
import { FiTrash2 } from "react-icons/fi";
import { getMultiContact } from "../../client/methods";

const ContactGroupList = ({
  contactsIds = [],
  handleContactFromGroup = () => {},
}) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    contactsIds.length > 0 ? getContactGroups(contactsIds) : setList([]);
  }, [contactsIds]);

  const getContactGroups = async (input) => {
    try {
      const result = await getMultiContact(input);

      setList(result.data.response);
    } catch (error) {
      console.log("🚀 ~ file: index.js:16 ~ getContactGroups ~ error", error);
    }
  };

  if (list.length > 0)
    return (
      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>

              <th></th>
            </tr>
          </thead>
          <tbody>
            {list.map((obj, i) => (
              <tr key={`contact_list_${i}`}>
                <td>{obj.name}</td>
                <td>{obj.email}</td>
                <td className="text-end">
                  <FiTrash2
                    className="like-btn"
                    size={20}
                    onClick={() => handleContactFromGroup(obj._id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  else return null;
};

export default ContactGroupList;
