import React, { useEffect, useRef } from "react";
import toast from "react-hot-toast";
import Toggle from "react-toggle";
import DatePicker from "react-datepicker";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";

// import DocumentUpload from "../document-upload";
import DocumentList from "../document-list";
import {
  createProperty,
  getProperty,
  updateProperty,
} from "../../client/methods";
import { documentTypeIds, documentDependIds } from "../../utils/options";
import { responseToastMsg } from "../../utils/response-message";
import { propertySchema } from "../../utils/validation-schema";
import { FaArrowLeft, FaSave } from "react-icons/fa";

import "./style.scss";
import { currencyFormat, numberFormatter } from "../../utils/common";
import AppDollarInput from "../app-dollar-input";
import BulkUpload from "../media/bulk-upload";

const PropertyForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const init = {
    name: "",
    city: "",
    state: "",
    project_type: "",
    google_pin_link: "",
    is_active: false,
    is_deck_active: false,
    property_image: [],
    project_deck_documents: [],
    targeted_investor_irr: "",
    no_of_buildings: "",
    deck_link: "",
    deck_name: "",
    dollar_per_unit: "",
    targeted_equity_multiple: "",
    project_cost: "",
    project_debt: "",
    project_equity: "",
    pref_equity: "",
    gp_equity: "",
    exit_price: "",
    lp_equity: "",
    acreage: "",
    units: "",
    address: "",
    roi: "",
  };

  const stateRef = useRef();

  const onSubmit = async (values) => {
    const { name } = values;

    if (name) {
      try {
        if (id && id !== "new") {
          const result = await updateProperty(id, values);
          responseToastMsg(result.data.response_code);
        } else {
          const result = await createProperty(values);
          responseToastMsg(result.data.response_code);
          navigate("/properties");
        }
      } catch (error) {
        responseToastMsg(error.data.error_code);
        console.log(
          "🚀 ~ file: index.js ~ line 59 ~ handleSave ~ error",
          error
        );
      }
    } else {
      toast.error("Fill all the mandatory * fields");
    }
  };

  const formik = useFormik({
    initialValues: init,
    validationSchema: propertySchema,
    onSubmit,
  });

  stateRef.current = formik.values;

  useEffect(() => {
    if (id === "new") {
      formik.setValues(init);
    } else {
      id && handleGetUser(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleGetUser = async (input) => {
    try {
      const result = await getProperty(input);

      formik.setValues({ ...result.data.response });
    } catch (error) {
      responseToastMsg(error.data.error_code);
      console.log(
        "🚀 ~ file: index.js ~ line 36 ~ handleGetUser ~ error",
        error
      );
    }
  };

  const handleUpdateDebt = (e) => {
    var p_cost = 0,
      p_equity = 0;
    if (e.target.name === "project_cost") {
      p_cost = parseInt(e.target.value);
      p_equity = parseInt(formik.values.project_equity);
      handleUpdateDollarPerUnit(e);
    } else {
      p_cost = parseInt(formik.values.project_cost);
      p_equity = parseInt(e.target.value);
    }
    p_cost > 0 &&
      p_equity > 0 &&
      formik.setFieldValue(
        "project_debt",
        parseInt(p_cost) - parseInt(p_equity)
      );
  };

  const handleUpdateDollarPerUnit = (e) => {
    var p_cost = 0,
      _unit = 0;
    if (e.target.name === "project_cost") {
      p_cost = parseInt(e.target.value);
      _unit = parseInt(formik.values.units);
    } else {
      p_cost = parseInt(formik.values.project_cost);
      _unit = parseInt(e.target.value);
    }
    p_cost > 0 &&
      _unit > 0 &&
      formik.setFieldValue(
        "dollar_per_unit",
        parseInt(p_cost) / parseInt(_unit)
      );
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="pb-2 sticky-top mb-2 bg-white bottom-shadow">
          <Link
            to={"/properties"}
            className="btn btn-dark btn-sm ps-3 pe-3 me-2"
          >
            <FaArrowLeft /> Back to Property List
          </Link>
          <button
            type="submit"
            className="btn btn-success btn-sm ps-3 pe-3 me-2"
          >
            <FaSave /> {id === "new" ? "Save" : "Update"}
          </button>
        </div>
        <div className="container-fluid">
          <div className="part-section">
            <h6> Property Info </h6>
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-2">
                      <label>Name*</label>
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        size="sm"
                        value={formik.values.name}
                        name="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={
                          formik.touched.name && formik.errors.name
                            ? "is-invalid"
                            : !formik.errors.name && "is-valid"
                        }
                      />
                      {formik.errors.name && formik.touched.name && (
                        <p className="text-danger">{formik.errors.name}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mb-2">
                      <label>City*</label>
                      <Form.Control
                        type="text"
                        placeholder="city"
                        size="sm"
                        value={formik.values.city}
                        name="city"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={
                          formik.touched.city && formik.errors.city
                            ? "is-invalid"
                            : !formik.errors.city && "is-valid"
                        }
                      />
                      {formik.errors.city && formik.touched.city && (
                        <p className="text-danger">{formik.errors.city}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mb-2">
                      <label>State*</label>
                      <Form.Control
                        type="text"
                        placeholder="state"
                        size="sm"
                        value={formik.values.state}
                        name="state"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={
                          formik.touched.state && formik.errors.state
                            ? "is-invalid"
                            : !formik.errors.state && "is-valid"
                        }
                      />
                      {formik.errors.state && formik.touched.state && (
                        <p className="text-danger">{formik.errors.state}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mb-2">
                      <label>Google Map Pin Link</label>
                      <Form.Control
                        type="text"
                        placeholder="Google Map Pin Link"
                        size="sm"
                        value={formik.values.google_pin_link}
                        name="google_pin_link"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mb-2">
                      <label>Active</label> <br />
                      <Toggle
                        className="mt-2"
                        checked={formik.values.is_active}
                        name="is_active"
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-2">
                      <label>Deck Name</label>
                      <Form.Control
                        type="text"
                        placeholder="Deck Name"
                        size="sm"
                        value={formik.values.deck_name}
                        name="deck_name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mb-2">
                      <label>Deck Link</label>
                      <Form.Control
                        type="text"
                        placeholder="Deck link"
                        size="sm"
                        value={formik.values.deck_link}
                        name="deck_link"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-2">
                      <label>Deck Link</label> <br />
                      <Toggle
                        className="mt-2"
                        checked={formik.values.is_deck_active}
                        name="is_deck_active"
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="part-section">
            <div className="mb-2">
              <h6> Property Image * </h6>
              <BulkUpload
                callback={(input) => {
                  formik.setFieldValue("property_image", [
                    ...stateRef.current.property_image,
                    ...input,
                  ]);
                }}
                documentType={documentTypeIds.investor}
                dependsTo={documentDependIds.investor}
              />
              <DocumentList
                docs={formik.values.property_image}
                deleteCallback={(_data) => {
                  formik.setFieldValue(
                    "property_image",
                    formik.values.property_image.filter((xx) => xx !== _data)
                  );
                }}
              />
            </div>
          </div>

          <div className="part-section">
            <div className="mb-2">
              <h6> Project Deck * </h6>
              <BulkUpload
                callback={(input) => {
                  formik.setFieldValue("project_deck_documents", [
                    ...stateRef.current.project_deck_documents,
                    ...input,
                  ]);
                }}
                documentType={documentTypeIds.investor}
                dependsTo={documentDependIds.investor}
              />
              <DocumentList
                docs={formik.values.project_deck_documents}
                deleteCallback={(_data) => {
                  formik.setFieldValue(
                    "project_deck_documents",
                    formik.values.project_deck_documents.filter(
                      (xx) => xx !== _data
                    )
                  );
                }}
              />
            </div>
          </div>

          <div className="part-section">
            <h6> Property Details </h6>
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-2">
                      <label>Project Cost</label>
                      <AppDollarInput
                        type="text"
                        size="sm"
                        placeholder="Project Cost"
                        value={formik.values.project_cost}
                        name="project_cost"
                        onChange={formik.handleChange}
                        onBlur={handleUpdateDebt}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-2">
                      <label>Equity Raise</label>
                      <AppDollarInput
                        type="text"
                        placeholder="Equity Raise"
                        size="sm"
                        value={formik.values.project_equity}
                        name="project_equity"
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        onBlur={handleUpdateDebt}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mb-2">
                      <label>Project Debt</label>
                      <AppDollarInput
                        // disabled
                        type="text"
                        size="sm"
                        placeholder="Project Debt"
                        value={formik.values.project_debt}
                        name="project_debt"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mb-2">
                      <label>Pref Equity</label>
                      <AppDollarInput
                        type="text"
                        placeholder="Pref Equity"
                        size="sm"
                        value={formik.values.pref_equity}
                        name="pref_equity"
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        onBlur={handleUpdateDebt}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mb-2">
                      <label>LP Equity</label>
                      <AppDollarInput
                        type="text"
                        placeholder="LP Equity"
                        size="sm"
                        value={formik.values.lp_equity}
                        name="lp_equity"
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        onBlur={handleUpdateDebt}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mb-2">
                      <label>GP Equity</label>
                      <AppDollarInput
                        type="text"
                        placeholder="GP Equity"
                        size="sm"
                        value={formik.values.gp_equity}
                        name="gp_equity"
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        onBlur={handleUpdateDebt}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mb-2">
                      <label>Acreage</label>
                      <Form.Control
                        type="text"
                        placeholder="Acreage"
                        size="sm"
                        value={formik.values.acreage}
                        name="acreage"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-2">
                      <label>Units</label>
                      <Form.Control
                        type="text"
                        placeholder="Units"
                        size="sm"
                        value={formik.values.units}
                        name="units"
                        onChange={formik.handleChange}
                        onBlur={handleUpdateDollarPerUnit}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-2">
                      <label>Dollar per Unit($)</label>
                      <AppDollarInput
                        decimal={0}
                        // disabled
                        type="text"
                        placeholder="Dollar per Unit"
                        size="sm"
                        value={formik.values.dollar_per_unit}
                        name="dollar_per_unit"
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-2">
                      <label>Type</label>
                      <Form.Control
                        type="text"
                        placeholder="Project Type"
                        size="sm"
                        value={formik.values.project_type}
                        name="project_type"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-2">
                      <label>No of Buildings</label>
                      <Form.Control
                        type="text"
                        placeholder="No of Buildings"
                        size="sm"
                        value={formik.values.no_of_buildings}
                        name="no_of_buildings"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-2">
                      <label>Address</label>
                      <Form.Control
                        type="text"
                        placeholder="Address"
                        size="sm"
                        value={formik.values.address}
                        name="address"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-2">
                      <label>Targeted IRR(%)</label>
                      <Form.Control
                        type="text"
                        placeholder="Targeted IRR"
                        size="sm"
                        value={formik.values.targeted_investor_irr}
                        name="targeted_investor_irr"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-2">
                      <label>Targeted Equity Multiple</label>
                      <Form.Control
                        type="text"
                        placeholder="Targeted Equity Multiple"
                        size="sm"
                        value={formik.values.targeted_equity_multiple}
                        name="targeted_equity_multiple"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-2">
                      <label>Targeted ROI(%)</label>
                      <Form.Control
                        type="text"
                        placeholder="Targeted ROI"
                        size="sm"
                        value={formik.values.roi}
                        name="roi"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mb-2">
                      <label>Exit Price</label>
                      <AppDollarInput
                        type="text"
                        placeholder="Exit Price"
                        size="sm"
                        value={formik.values.exit_price}
                        name="exit_price"
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default PropertyForm;
