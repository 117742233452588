import axios from "./axios-utils";

// auth-user

export const loginApi = (props) => axios.post("/auth-user/login", props);

// users apis
export const createUser = (props) =>
  axios.post("/admin/user/create-user", props);

export const resendInvite = (id) =>
  axios.post(`/admin/user/resend-invite/${id}`);

export const resetPasswordAdmin = (props) =>
  axios.post("/admin/user/reset-password-admin", props);

export const changePasswordAdmin = (props) =>
  axios.post("/admin/user/change-password", props);

export const createPasswordAdmin = (props) =>
  axios.post("/admin/user/create-password", props);

export const updateUser = (id, props) =>
  axios.put(`/admin/user/update-user/${id}`, props);

export const getUser = (id) => axios.get(`/admin/user/get-user/${id}`);

export const geUserList = (list_size, count, search = "") =>
  axios.get(`/admin/user/user-list/${list_size}/${count}/${search}`);

export const deleteUser = (id) => axios.delete(`/admin/user/delete-user/${id}`);

// listing
export const createListing = (props) =>
  axios.post("/admin/listing/create-listing", props);

export const updateListing = (id, props) =>
  axios.put(`/admin/listing/update-listing/${id}`, props);

export const updateRequestTokenize = (id, props) =>
  axios.put(`/admin/listing/update-request-tokenize/${id}`, props);

export const getListing = (id) => axios.get(`/admin/listing/get-listing/${id}`);

export const getListingList = (list_size, count, search = "") =>
  axios.get(`/admin/listing/listing-list/${list_size}/${count}/${search}`);

export const deleteListing = (id) =>
  axios.delete(`/admin/listing/delete-listing/${id}`);

// documents
export const createDocument = (props) =>
  axios.post("/admin/document/create-document", props);

export const updateDocument = (id, props) =>
  axios.put(`/admin/document/update-document/${id}`, props);

export const getDocument = (id) =>
  axios.get(`/admin/document/get-document/${id}`);

export const getMultiDocument = (ids) =>
  axios.post(`/admin/document/get-multi-documents`, {
    ids,
  });

export const getDocumentList = (list_size, count, search = "") =>
  axios.get(`/admin/document/document-list/${list_size}/${count}/${search}`);

export const deleteDocument = (data) =>
  axios.post(`/admin/document/delete-document`, { doc: data });

// contacts
export const createContact = (props) =>
  axios.post("/admin/contact/create-contact", props);

export const updateContact = (id, props) =>
  axios.put(`/admin/contact/update-contact/${id}`, props);

export const getContact = (id) => axios.get(`/admin/contact/get-contact/${id}`);

export const getMultiContact = (ids) =>
  axios.post(`/admin/contact/get-multi-contacts`, {
    ids,
  });

export const getContactList = (list_size, count, search = "") =>
  axios.get(`/admin/contact/contact-list/${list_size}/${count}/${search}`);

export const deleteContact = (data) =>
  axios.post(`/admin/contact/delete-contact`, { _id: data });

// websites
export const createWebsite = (props) =>
  axios.post("/admin/website/create-website", props);

export const updateWebsite = (id, props) =>
  axios.put(`/admin/website/update-website/${id}`, props);

export const getWebsite = (id) => axios.get(`/admin/website/get-website/${id}`);

export const getWebsiteList = (list_size, count, search = "") =>
  axios.get(`/admin/website/website-list/${list_size}/${count}/${search}`);

export const deleteWebsite = (data) =>
  axios.post(`/admin/website/delete-website`, { _id: data });

// interest
export const createInterest = (props) =>
  axios.post("/admin/interest/create-interest", props);

export const updateInterest = (id, props) =>
  axios.put(`/admin/interest/update-interest/${id}`, props);

export const getInterest = (id) =>
  axios.get(`/admin/interest/get-interest/${id}`);

export const getInterestList = (list_size, count, search = "") =>
  axios.get(`/admin/interest/interest-list/${list_size}/${count}/${search}`);

export const deleteInterest = (data) =>
  axios.post(`/admin/interest/delete-interest`, { _id: data });

// email
export const createEmail = (props) =>
  axios.post("/admin/email/create-email", props);

export const getEmail = (id) => axios.get(`/admin/email/get-email/${id}`);

export const getEmailList = (list_size, count, search = "") =>
  axios.get(`/admin/email/email-list/${list_size}/${count}/${search}`);

// export const deleteEmail = (data) =>
//   axios.post(`/admin/email/delete-email`, { _id: data });

// property
export const createProperty = (props) =>
  axios.post("/admin/property/create-property", props);

export const updateProperty = (id, props) =>
  axios.put(`/admin/property/update-property/${id}`, props);

export const getProperty = (id) =>
  axios.get(`/admin/property/get-property/${id}`);

export const getMultiProperty = (ids) =>
  axios.post(`/admin/property/get-multi-property`, {
    ids,
  });

export const getPropertyList = (list_size, count, search = "") =>
  axios.get(`/admin/property/property-list/${list_size}/${count}/${search}`);

export const deleteProperty = (data) =>
  axios.post(`/admin/property/delete-property`, { _id: data });

// contacts group
export const createContactGroup = (props) =>
  axios.post("/admin/contact-group/create-contact-group", props);

export const updateContactGroup = (id, props) =>
  axios.put(`/admin/contact-group/update-contact-group/${id}`, props);

export const getContactGroup = (id) =>
  axios.get(`/admin/contact-group/get-contact-group/${id}`);

export const getMultiContactGroup = (ids) =>
  axios.post(`/admin/contact-group/get-multi-contact-groups`, {
    ids,
  });

export const getContactGroupList = (list_size, count, search = "") =>
  axios.get(
    `/admin/contact-group/contact-group-list/${list_size}/${count}/${search}`
  );

export const deleteContactGroup = (data) =>
  axios.post(`/admin/contact-group/delete-contact-group`, { _id: data });

// transaction
export const createTransaction = (props) =>
  axios.post("/admin/transaction/create-transaction", props);

export const updateTransaction = (id, props) =>
  axios.put(`/admin/transaction/update-transaction/${id}`, props);

export const getTransaction = (id) =>
  axios.get(`/admin/transaction/get-transaction/${id}`);

export const getTransactionList = (list_size, count, search = "") =>
  axios.get(
    `/admin/transaction/transaction-list/${list_size}/${count}/${search}`
  );

export const deleteTransaction = (id) =>
  axios.delete(`/admin/transaction/delete-transaction/${id}`);

// investors
export const createInvestor = (props) =>
  axios.post("/admin/investor/create-investor", props);

export const updateInvestor = (id, props) =>
  axios.put(`/admin/investor/update-investor/${id}`, props);

export const getInvestor = (id) =>
  axios.get(`/admin/investor/get-investor/${id}`);

export const getInvestorList = (list_size, count, search = "") =>
  axios.get(`/admin/investor/investor-list/${list_size}/${count}/${search}`);

export const getInvestorCombo = (list_size, count, search = "") =>
  axios.get(
    `/admin/investor/get-investor-combo/${list_size}/${count}/${search}`
  );

export const deleteInvestor = (id) =>
  axios.delete(`/admin/investor/delete-investor/${id}`);

// Investing Accounts
export const createInvestingAccounts = (props) =>
  axios.post("/admin/investing-accounts/create-investing-accounts", props);

export const updateInvestingAccounts = (id, props) =>
  axios.put(`/admin/investing-accounts/update-investing-accounts/${id}`, props);

export const getInvestingAccounts = (id) =>
  axios.get(`/admin/investing-accounts/get-investing-accounts/${id}`);

export const getMultiInvestingAccounts = (ids) =>
  axios.post(`/admin/investing-accounts/get-multi-investing-accounts`, {
    ids,
  });

export const getInvestingAccountsList = (
  investor_id,
  list_size,
  count,
  search = ""
) =>
  axios.get(
    `/admin/investing-accounts/investing-accounts-list/${investor_id}/${list_size}/${count}/${search}`
  );

export const deleteInvestingAccounts = (data) =>
  axios.post(`/admin/investing-accounts/delete-investing-accounts`, {
    _id: data,
  });

// Investments
export const createInvestment = (props) =>
  axios.post("/admin/investment/create-investment", props);

export const updateInvestment = (id, props) =>
  axios.put(`/admin/investment/update-investment/${id}`, props);

export const getInvestment = (id) =>
  axios.get(`/admin/investment/get-investment/${id}`);

export const getMultiInvestment = (ids) =>
  axios.post(`/admin/investment/get-multi-investment`, {
    ids,
  });

export const getInvestmentList = (list_size, count, search = "") =>
  axios.get(
    `/admin/investment/investment-list/${list_size}/${count}/${search}`
  );

export const deleteInvestment = (data) =>
  axios.post(`/admin/investment/delete-investment`, { _id: data });

// Distributions
export const createDistribution = (props) =>
  axios.post("/admin/distribution/create-distribution", props);

export const updateDistribution = (id, props) =>
  axios.put(`/admin/distribution/update-distribution/${id}`, props);

export const getDistribution = (id) =>
  axios.get(`/admin/distribution/get-distribution/${id}`);

export const getMultiDistribution = (ids) =>
  axios.post(`/admin/distribution/get-multi-distribution`, {
    ids,
  });

export const getDistributionList = (list_size, count, search = "") =>
  axios.get(
    `/admin/distribution/distribution-list/${list_size}/${count}/${search}`
  );

export const deleteDistribution = (data) =>
  axios.post(`/admin/distribution/delete-distribution`, { _id: data });

// comments
export const createComment = (props) =>
  axios.post("/admin/comment/create-comment", props);

export const getComment = (entity, id, list_size, count) =>
  axios.get(`/admin/comment/get-comment/${entity}/${id}/${list_size}/${count}`);

export const updateComment = (id, props) =>
  axios.put(`/admin/comment/update-comment/${id}`, props);

// media

export const deleteMedia = (input) =>
  axios.post("/admin/media/delete-media", {
    public_id: input.public_id,
  });
