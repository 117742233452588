import React from "react";

import InterestList from "./list";

import "./style.scss";

const Interest = () => {
  return <InterestList />;
};

export default Interest;
