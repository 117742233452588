import routeUrls from "./route-urls";

export const roleIds = {
  leads: "leads",
  listings: "listings",
  tokens: "tokens",
  customers: "customers",
  transactions: "transactions",
  dashboard: "dashboard",
  users: "users",
  role: "role",
  reset_password: "reset_password",
  change_password: "change_password",
};

export const permissions = [
  {
    label: "Leads",
    value: roleIds.leads,
    url: routeUrls.lead,
  },
  {
    label: "Listings",
    value: roleIds.listings,
    url: routeUrls.listing,
  },
  {
    label: "Tokens",
    value: roleIds.tokens,
    url: routeUrls.token,
  },
  {
    label: "Transactions",
    value: roleIds.transactions,
    url: routeUrls.transacton,
  },
  {
    label: "Customers",
    value: roleIds.customers,
    url: routeUrls.customer,
  },
  {
    label: "Dashboard",
    value: roleIds.dashboard,
    url: routeUrls.dashboard,
  },
  {
    label: "Users",
    value: roleIds.users,
    url: routeUrls.user,
  },
  {
    label: "Roles",
    value: roleIds.role,
    url: routeUrls.role,
  },
  {
    label: "reset_password",
    value: roleIds.reset_password,
  },
  {
    label: "change_Password",
    value: roleIds.change_password,
    url: routeUrls.change_password,
  },
];

export const residenceTypeOptions = [
  { label: "I'm a U.S. citizen", value: "citizen" },
  { label: "I'm a U.S. resident alien", value: "resident_citizen" },
  { label: "I'm a U.S. non-resident alien", value: "non_resident_citizen" },
  { label: "Other", value: "other" },
];

export const maritalStatusOptions = [
  { label: "Single", value: "single" },
  { label: "Married", value: "married" },
  { label: "Domestic Partner", value: "domestic_partner" },
  { label: "Divorced", value: "divorced" },
];

export const regulatoryTypesOptions = [
  { label: "Broker-dealer", value: "broker_dealer" },
  { label: "Investment advisor", value: "investment_advisor" },
  {
    label: "FINRA or other SRO (self-regulatory organization)",
    value: "finra_sro",
  },
  {
    label: "State or Federal securities regulator",
    value: "state_federal_securities_regulator",
  },
];

export const propertyTypesOptions = [
  { label: "Built-to-Rent", value: "built_to_rent" },
  { label: "Co-Living", value: "co_living" },
  { label: "Data Center", value: "data_center" },
  { label: "Flex R&D", value: "flex_randD" },
  { label: "Flex/Office", value: "flex_office" },
  { label: "Hospitality", value: "hospitality" },
  { label: "Industrial", value: "industrial" },
  { label: "Land", value: "land" },
  { label: "Manufactured Housing", value: "manufactured_housing" },
  { label: "Medical Office", value: "medical_office" },
  { label: "Mixed Use", value: "mixed_use" },
  { label: "Multi-Asset", value: "multi_asset" },
  { label: "Multifamily", value: "multifamily" },
  { label: "Office", value: "office" },
  { label: "Parking Garage", value: "parking_garage" },
  { label: "Retail", value: "retail" },
  { label: "Senior Housing", value: "senior_housing" },
  { label: "Single Family", value: "single_family" },
  { label: "Specialty", value: "specialty" },
  { label: "Storage", value: "storage" },
  { label: "Student Housing", value: "student_housing" },
];

export const regionOptions = [
  { label: "US", value: "us" },
  { label: "My State", value: "my_state" },
  { label: "Midwest", value: "midwest" },
  { label: "Northeast", value: "northeast" },
  { label: "Northwest", value: "northwest" },
  { label: "Southeast", value: "southeast" },
  { label: "Southwest", value: "southwest" },
  { label: "Southern", value: "southern" },
  { label: "Western", value: "western" },
];

export const marketSizeOptions = [
  { label: "Primary", value: "primary" },
  { label: "Secondary", value: "secondary" },
  { label: "Tertiary", value: "tertiary" },
];

export const vechilesOptions = [
  { label: "Funds", value: "funds" },
  { label: "Properties", value: "properties" },
];

export const listingTypeOptions = [
  { label: "Single Project", value: "single" },
  { label: "Portfolio - Multiple Project", value: "multiple" },
];

export const holdPeriodOptions = [
  { label: "Less than 1 Year", value: "less_than_1_year" },
  { label: "1-2 Years", value: "1_2_years" },
  { label: "3-5 Years", value: "3_5_years" },
  { label: "6-9 Years", value: "6_9_years" },
  { label: "10+ Years", value: "10_years_above" },
];

export const objectivesOptions = [
  { label: "Aggressive", value: "aggressive" },
  { label: "Growth", value: "growth" },
  { label: "Income", value: "income" },
];

export const industryOptions = [
  { label: "Biotechnology", value: "biotechnology" },
  { label: "Computer Software", value: "computer_software" },
  { label: "Construction", value: "construction" },
  { label: "Engineering", value: "engineering" },
  { label: "Entertainment", value: "entertainment" },
  {
    label: "Financial Services & Banking",
    value: "financial_services_and_banking",
  },
  { label: "Government", value: "government" },
  { label: "Government Contracting", value: "government_contracting" },
  { label: "Healthcare", value: "healthcare" },
  { label: "Hospitality", value: "hospitality" },
  {
    label: "Information Technology Services",
    value: "information_technology_services",
  },
  { label: "Insurance", value: "insurance" },
  { label: "legal", value: "legal" },
  { label: "Management Consulting", value: "management_consulting" },
  { label: "Marketing & Advertising", value: "marketing_advertising" },
  { label: "No Industry Selected", value: "no_industry_selected" },
  { label: "PR & Communications", value: "pr_communications" },
  { label: "Real Estate", value: "real_estate" },
  { label: "Retails", value: "retails" },
  {
    label: "Small Business/Entrepreneur",
    value: "small_business_entrepreneur",
  },
  { label: "Telecommunications", value: "telecommunications" },
  { label: "Others", value: "others" },
];

export const documentTypeIds = {
  regulatory: "regulatory_documents",
  government: "government_documents",
  investor: "investor_documents",
};

export const documentTypes = [
  { label: "Regulatory Documents", value: documentTypeIds.regulatory },
  { label: "Government Documents", value: documentTypeIds.government },
  { label: "Investor Documents", value: documentTypeIds.investor },
];

export const documentDependIds = {
  investor: "investor",
  sponsor: "sponsor",
};

export const documentDepends = [
  { label: "Investor", value: documentDependIds.investor },
  { label: "Sponsor", value: documentDependIds.sponsor },
];

export const investmentInTwelveMonthsOptions = [
  { label: "Undecided", value: "undecided" },
  { label: "Less Than $100,000", value: "less_than_100000" },
  { label: "$100,000 - $249,999", value: "100000_249999" },
  { label: "$250,000 - $499,999", value: "250000_499999" },
  { label: "$500,000 - $999,999", value: "500000_999999" },
  { label: "$1,000,000+", value: "more_than_1000000" },
];

export const investmentAmountPerProjectOptions = [
  { label: "Undecided", value: "undecided" },
  { label: "$10,000 - $24,999", value: "10000_24999" },
  { label: "$25,000 - $49,999", value: "25000_49999" },
  { label: "$50,000 - $99,999", value: "50000_99999" },
  { label: "$100,000 - $249,999", value: "100000_249999" },
  { label: "$250,000 - $499,999", value: "250000_499999" },
  { label: "$500,000 - $999,999", value: "500000_999999" },
  { label: "$1,000,000+", value: "more_than_1000000" },
];

export const riskToleranceOptions = [
  { label: "Undecided", value: "undecided" },
  { label: "Risk Averse", value: "risk_averse" },
  { label: "Moderate", value: "moderate" },
  { label: "Risk Tolerant", value: "risk_tolerant" },
];

export const goalsRealEstateInvestmentsOptions = [
  {
    label: "Appreciation (potential for investment return)",
    value: "Appreciation",
  },
  {
    label: "Income (cash flow during the life of the investment)",
    value: "income_cashflow",
  },
  {
    label:
      "Diversification (adding private real estate to your overall portfolio of investments)",
    value: "diversification",
  },
  {
    label: "Opportunity Zone Program (tax incentives)",
    value: "opportunity_zone_program",
  },
  { label: "ESG (Environmental, Social, Governance)", value: "esg" },
  { label: "Hedge against inflation", value: "hedge_against_inflation" },
  { label: "None", value: "none" },
];

export const generalInvestingExperienceOptions = [
  { label: "Alternative assets", value: "alternative_assets" },
  { label: "Bonds", value: "bonds" },
  {
    label: "Direct real estate ownership",
    value: "direct_real_estate_ownership",
  },
  { label: "Private equity", value: "private_equity" },
  { label: "REITs", value: "reits" },
  { label: "Stocks & mutual funds", value: "stocks_mutual_funds" },
  { label: "Venture capital", value: "venture_capital" },
  { label: "None", value: "none" },
];

export const CREInvestingExperienceCheckOptions = [
  {
    label: "Direct participation program",
    value: "direct_participation_program",
  },
  { label: "Non-traded REIT", value: "non-traded_REIT" },
  { label: "Private offering", value: "private_offering" },
  { label: "Public traded REIT", value: "public_traded_REIT" },
  { label: "None", value: "none" },
];

export const approximateNetWorthOptions = [
  { label: "Less than $250,000", value: "less_than_250000" },
  { label: "$250,000 to $500,000", value: "250000_500000" },
  { label: "$500,000 to $1 million", value: "500000_1_million" },
  { label: "$1 million to $2.5 million", value: "1million_2.5million" },
  { label: "$2.5 million to $5 million", value: "2.5million_5million" },
  { label: "$5 million or more", value: "5million_and_above" },
];

export const CREInvestingExperienceRadioOptions = [
  { label: "None", value: "none" },
  { label: "Moderate", value: "moderate" },
  { label: "Extensive", value: "extensive" },
  { label: "Professional", value: "professional" },
];

export const supportingMemberOptions = [
  { label: "Accountant", value: "accountant" },
  { label: "Advisor", value: "advisor" },
  { label: "Attorney", value: "attorney" },
  { label: "Proxy", value: "proxy" },
];

export const signatoryStatusOptions = [
  { label: "Non-signatory", value: "non_signatory" },
  { label: "Signatory", value: "signatory" },
];

export const preferredDistributionMethodOptions = [
  { label: "ACH", value: "ach" },
  { label: "Wire", value: "wire" },
  { label: "Mail", value: "mail" },
];

export const accountTypesOptions = [
  { label: "Checking", value: "checking" },
  { label: "Saving", value: "saving" },
];
