import React, { useEffect, useState } from "react";
import { BsTrash } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

import { getInterestList, getListing } from "../../client/methods";
import { dateFormat, list_size } from "../../utils/common";
import { responseToastMsg } from "../../utils/response-message";

const InterestsList = () => {
  const navigate = useNavigate();

  const [count, setCount] = useState(0);
  const [list, setList] = useState([]);
  const [loadHide, setLoadHide] = useState(false);

  useEffect(() => {
    getList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getList = async () => {
    try {
      const result = await getInterestList(list_size, count);

      setList([...list, ...result.data.response]);
      setCount(count + result.data.response.length);
      if (result.data.response.length === 0) {
        setLoadHide(true);
      }
    } catch (error) {
      responseToastMsg(error.data.error_code);
    }
  };

  return (
    <>
      <div className="content-view">
        {/* <div className="pb-2 sticky-top mb-2 bg-color-default">
          <Link to={"/interest/new"} className="btn btn-dark btn-sm ps-4 pe-4">
            <FaPlus /> New Interest
          </Link>
        </div> */}

        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th> Name </th>
                <th> Email </th>
                <th> Project/Portfolio </th>
                <th> Amount </th>
                <th> Interested On </th>
              </tr>
            </thead>
            <tbody>
              {list.map((obj, i) => (
                <tr
                  key={`ulist${i}`}
                  onClick={() => navigate(`/interest/${obj._id}`)}
                  className="cursor-pointer"
                >
                  <td>{obj.name}</td>
                  <td>{obj.email}</td>
                  <td>
                    <ListingName listing_id={obj.listing_id} />
                  </td>
                  <td>{obj.offering_amount}</td>
                  <td>{dateFormat(obj.createdAt)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {!loadHide && (
            <div className="text-center mt-2">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={() => getList()}
              >
                Load more
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const ListingName = ({ listing_id }) => {
  console.log("🚀 ~ file: list.js:93 ~ ListingName ~ listing_id", listing_id);
  const [listing, setListing] = useState();

  const getListingDetails = async (_id) => {
    try {
      const result = await getListing(_id);

      setListing(result.data.response);
    } catch (error) {
      console.log("🚀 ~ file: list.js:97 ~ getListingDetails ~ error", error);
    }
  };
  useEffect(() => {
    listing_id && getListingDetails(listing_id);
  }, [listing_id]);

  return listing ? listing?.title : null;
};

export default InterestsList;
