import React from "react";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";

import { createPasswordAdmin } from "../../client/methods";
import { responseToastMsg } from "../../utils/response-message";
import { createPasswordSchema } from "../../utils/validation-schema";
import { userLogoutThunk } from "../../redux/thunk/user.thunk";

const CreatePassword = () => {
  const { data } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    const { create_confirm_password } = values;

    try {
      const result = await createPasswordAdmin({
        id: data.user_id,
        confirm_password: create_confirm_password,
      });
      responseToastMsg(result.data.response_code);
      dispatch(userLogoutThunk());
    } catch (error) {
      responseToastMsg(error.data.error_code);
    }
  };

  const { values, handleChange, handleBlur, errors, touched, handleSubmit } =
    useFormik({
      initialValues: {
        create_new_password: "",
        create_confirm_password: "",
      },
      validationSchema: createPasswordSchema,
      onSubmit,
    });

  return (
    <div>
      <div className="d-flex align-items-center auth px-0">
        <div className="row w-100 mx-0">
          <div className="col-lg-4 mx-auto">
            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
              <h5>Create New Password</h5>
              <form autoComplete="off" onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label>New Password</label>
                  <Form.Control
                    type="password"
                    placeholder="New Password"
                    size="sm"
                    value={values.create_new_password}
                    name="create_new_password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      touched.create_new_password && errors.create_new_password
                        ? "is-valid"
                        : !errors.create_new_password && "is-valid"
                    }
                  />
                  {errors.create_new_password &&
                    touched.create_new_password && (
                      <p className="text-danger">
                        {errors.create_new_password}
                      </p>
                    )}
                </div>

                <div className="mb-3">
                  <label>Confirm Password</label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm Password"
                    size="sm"
                    value={values.create_confirm_password}
                    name="create_confirm_password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      touched.create_confirm_password &&
                      errors.create_confirm_password
                        ? "is-valid"
                        : !errors.create_confirm_password && "is-valid"
                    }
                  />
                  {errors.create_confirm_password &&
                    touched.create_confirm_password && (
                      <p className="text-danger">
                        {errors.create_confirm_password}
                      </p>
                    )}
                </div>
                <button type="submit" className="btn btn-dark btn-sm ps-4 pe-4">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePassword;
