import React from "react";
import { useParams } from "react-router-dom";

import DocumentsList from "./list";
import Document from "./form";

import "./style.scss";

const Documents = () => {
  const { id } = useParams();

  return <>{id ? <Document /> : <DocumentsList />}</>;
};

export default Documents;
