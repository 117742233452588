import React from "react";

import "./style.scss";
import BarChart from "../charts/bar";
import PieChart from "../charts/pie";
import MultiAxis from "../charts/multi-axis";
import Dougnut from "../charts/dougnut";
import Polar from "../charts/polar";

const Dashboard = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6">
          <div className="part-section">
            <BarChart />
          </div>
        </div>
        <div className="col-md-6">
          <div className="part-section">
            <MultiAxis />
          </div>
        </div>
        <div className="col-md-4">
          <div className="part-section">
            <PieChart />
          </div>
        </div>
        <div className="col-md-4">
          <div className="part-section">
            <Polar />
          </div>
        </div>
        <div className="col-md-4">
          <div className="part-section">
            <Dougnut />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
