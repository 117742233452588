import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import Toggle from "react-toggle";
import toast from "react-hot-toast";
import { DefaultEditor } from "react-simple-wysiwyg";
import validator from "validator";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";

import InputPhone from "../input-phone";
import {
  createListing,
  getListing,
  getPropertyList,
  updateListing,
} from "../../client/methods";
import { responseToastMsg } from "../../utils/response-message";
import { userSchema } from "../../utils/validation-schema";
import { FaArrowLeft, FaSave } from "react-icons/fa";
import {
  documentDependIds,
  documentTypeIds,
  listingTypeOptions,
} from "../../utils/options";
import { crispStyle } from "../../utils/common";
import PropertyGroupList from "../property-group-list";
// import BulkUpload from "../document-upload/index";
import DocumentList from "../document-list/index";
import AppDollarInput from "../app-dollar-input";
import "./style.scss";
import { FiTrash2 } from "react-icons/fi";
import BulkUpload from "../media/bulk-upload";

const ListingForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [propertyList, setPropertyList] = useState([]);
  const [tempProperty, setTempProperty] = useState(null);
  const [totals, setTotals] = useState({});
  const [webinar, setWebinar] = useState({
    title: "",
    link: "",
    time: "",
  });

  const init = {
    page_id: "",
    type: "single",
    title: "",
    properties: [],
    webinars: [],
    listing_documents: [],
    company_deck_documents: [],
    portfolio_deck_documents: [],
    listing_images: [],
    estimated_total_cost: "",
    total_debts: "",
    total_equity: "",
    minimum_investment: "",
    deck_link: "",
    deck_name: "",
    total_units: "",
    total_no_of_buildings: "",
    total_dollar_per_unit: "",
    maximum_investment: "",
    target_raise: "",
    is_active: false,
    listing_on: null,
    ending_on: null,
    target_irr: "",
    target_irr: "",
    target_equity_multiple: "",
    offering_description: "",
    targeted_investment_period: "",
    webinar_link: "",
  };

  const stateRef = useRef();

  const loadPropertyList = async () => {
    try {
      const result = await getPropertyList(20, 0);
      setPropertyList([...result.data.response]);
    } catch (error) {
      responseToastMsg(error.data.error_code);

      console.log(
        "🚀 ~ file: index.js ~ line 18 ~ loadPropertyList ~ error",
        error
      );
    }
  };

  useEffect(() => {
    loadPropertyList();
  }, []);

  const loadPropertyListSearch = async (search = "") => {
    try {
      const result = await getPropertyList(20, 0, search);

      return [...result.data.response].map((o) => ({
        label: o.name,
        value: o._id,
      }));
    } catch (error) {
      responseToastMsg(error.data.error_code);
      console.log(
        "🚀 ~ file: index.js ~ line 34 ~ loadCustomerListSearch ~ error",
        error
      );
    }
  };

  const onSubmit = async (values) => {
    const { title } = values;

    if (title) {
      try {
        if (id && id !== "new") {
          const result = await updateListing(id, values);

          responseToastMsg(result.data.response_code);
        } else {
          const result = await createListing(values);

          responseToastMsg(result.data.response_code);
          navigate("/listings");
        }
      } catch (error) {
        responseToastMsg(error.data.error_code);
        console.log(
          "🚀 ~ file: index.js ~ line 59 ~ handleSave ~ error",
          error
        );
      }
    } else {
      toast.error("Fill all the mandatory * fields");
    }
  };

  const formik = useFormik({
    initialValues: init,
    // validationSchema: userSchema,
    onSubmit,
  });

  stateRef.current = formik.values;

  useEffect(() => {
    if (id === "new") {
      formik.setValues(init);
    } else {
      id && handleGetUser(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      ...totals,
    });
  }, [totals]);

  const handleGetUser = async (input) => {
    try {
      const result = await getListing(input);

      formik.setValues({ ...result.data.response });
    } catch (error) {
      responseToastMsg(error.data.error_code);
      console.log(
        "🚀 ~ file: index.js ~ line 36 ~ handleGetUser ~ error",
        error
      );
    }
  };

  const handleAddProject = () => {
    if (!formik.values.properties.includes(tempProperty.value)) {
      formik.setFieldValue("properties", [
        ...formik.values.properties,
        tempProperty.value,
      ]);
    } else {
      responseToastMsg("ER019");
    }

    setTempProperty(null);
  };

  const handleCheck = () => {
    formik.setFieldValue(
      "properties",
      formik.values.properties.length > 0 ? [formik.values.properties[0]] : []
    );
  };

  const _type = formik.values.type;

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="pb-2 sticky-top mb-2 bg-white bottom-shadow">
          <Link to={"/listings"} className="btn btn-dark btn-sm ps-3 pe-3 me-2">
            <FaArrowLeft /> Back to Listings List
          </Link>
          <button
            type="submit"
            className="btn btn-success btn-sm ps-3 pe-3 me-2"
          >
            <FaSave /> {id === "new" ? "Save" : "Update"}
          </button>
        </div>
        <div className="container-fluid">
          <div className="part-section">
            <h6> Basic informations </h6>
            <div className="row">
              <div className="col-md-4">
                <div className="mb-2">
                  <label>Page ID*</label>
                  <Form.Control
                    type="text"
                    placeholder="/page_id"
                    size="sm"
                    value={formik.values.page_id}
                    name="page_id"
                    onChange={(e) =>
                      formik.setFieldValue(
                        "page_id",
                        e.target.value.toLowerCase().replace(/ /g, "")
                      )
                    }
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-2">
                  <label>Listing Type</label>
                  <Select
                    menuPosition={"fixed"}
                    value={
                      formik.values?.type
                        ? listingTypeOptions.find(
                            (o) => o.value === formik.values.type
                          )
                        : null
                    }
                    styles={crispStyle}
                    options={listingTypeOptions}
                    onChange={(data) => {
                      formik.handleChange("type")(data.value);
                      handleCheck();
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-2">
                  <label>Title*</label>
                  <Form.Control
                    type="text"
                    placeholder="Title for Listings"
                    size="sm"
                    value={formik.values.title}
                    name="title"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="mb-2">
                  <label>
                    Estimated{" "}
                    {_type === "single" ? "Project" : " Total Project"} Cost
                  </label>
                  <AppDollarInput
                    disabled
                    type="text"
                    size="sm"
                    value={formik.values.estimated_total_cost}
                    name="estimated_total_cost"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-2">
                  <label>
                    {_type === "single" ? "Project" : "Estimated Total Project"}{" "}
                    Equity
                  </label>
                  <AppDollarInput
                    disabled
                    type="text"
                    size="sm"
                    value={formik.values.total_equity}
                    name="total_equity"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-2">
                  <label>Total Debts</label>
                  <AppDollarInput
                    disabled
                    type="text"
                    size="sm"
                    value={formik.values.total_debts}
                    name="total_debts"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-2">
                  <label>Minimum Investment</label>
                  <AppDollarInput
                    type="text"
                    size="sm"
                    value={formik.values.minimum_investment}
                    name="minimum_investment"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-2">
                  <label>Maximum Investment</label>
                  <AppDollarInput
                    type="text"
                    size="sm"
                    value={formik.values.maximum_investment}
                    name="maximum_investment"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-2">
                  <label>Targeted Investment Period(years)</label>
                  <Form.Control
                    type="text"
                    placeholder="Targeted Investment Period"
                    size="sm"
                    value={formik.values.targeted_investment_period}
                    name="targeted_investment_period"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-2">
                  <label>Target Raise</label>
                  <AppDollarInput
                    type="text"
                    size="sm"
                    value={formik.values.target_raise}
                    name="target_raise"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="mb-2">
                  <label>Total Units</label>
                  <Form.Control
                    disabled
                    type="text"
                    placeholder="Total Units"
                    size="sm"
                    value={formik.values.total_units}
                    name="total_units"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-2">
                  <label>Total Dollar Per Unit</label>
                  <AppDollarInput
                    decimal={0}
                    disabled
                    type="text"
                    placeholder="Total Dollar Per Unit"
                    size="sm"
                    value={formik.values.total_dollar_per_unit}
                    name="total_dollar_per_unit"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-2">
                  <label>Total No Of Buildings</label>
                  <Form.Control
                    disabled
                    type="text"
                    placeholder="Total No Of Buildings"
                    size="sm"
                    value={formik.values.total_no_of_buildings}
                    name="total_no_of_buildings"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-2">
                  <label>Active</label> <br />
                  <Toggle
                    className="mt-2"
                    checked={formik.values.is_active}
                    name="is_active"
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-2">
                  <label>Default Page</label> <br />
                  <Toggle
                    className="mt-2"
                    checked={formik.values.is_page_default}
                    name="is_page_default"
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-2">
                  <label>Listing On</label>
                  <DatePicker
                    className="form-control"
                    dateFormat={"MMM dd yyyy"}
                    selected={
                      formik.values.listing_on &&
                      new Date(formik.values.listing_on)
                    }
                    onChange={(date) =>
                      formik.setFieldValue("listing_on", date)
                    }
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="mb-2">
                  <label>Ending On</label>
                  <DatePicker
                    className="form-control"
                    dateFormat={"MMM dd yyyy"}
                    selected={
                      formik.values.ending_on &&
                      new Date(formik.values.ending_on)
                    }
                    onChange={(date) => formik.setFieldValue("ending_on", date)}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="mb-2">
                  <label>Webinar Link</label>
                  <Form.Control
                    type="text"
                    size="sm"
                    value={formik.values.webinar_link}
                    name="webinar_link"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="mb-2">
                  <label>Deck Name</label>
                  <Form.Control
                    type="text"
                    placeholder="Deck Name"
                    size="sm"
                    value={formik.values.deck_name}
                    name="deck_name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-2">
                  <label>Deck Link</label>
                  <Form.Control
                    type="text"
                    placeholder="Deck link"
                    size="sm"
                    value={formik.values.deck_link}
                    name="deck_link"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="part-section">
            <h6>Webinars Schedules</h6>

            <div className="row">
              <div className="col-md-4">
                <label>Webinar Title/Description</label>
                <Form.Control
                  type="text"
                  size="sm"
                  value={webinar.title}
                  onChange={(e) =>
                    setWebinar({ ...webinar, title: e.target.value })
                  }
                />
              </div>
              <div className="col-md-4">
                <label>Webinar Time</label>
                <Form.Control
                  type="text"
                  size="sm"
                  value={webinar.time}
                  onChange={(e) =>
                    setWebinar({ ...webinar, time: e.target.value })
                  }
                />
              </div>
              <div className="col">
                <label>Webinar Link</label>
                <Form.Control
                  type="text"
                  size="sm"
                  value={webinar.link}
                  onChange={(e) =>
                    setWebinar({ ...webinar, link: e.target.value })
                  }
                />
              </div>
              <div className="col-md-12">
                <div className="mt-2">
                  <button
                    type="button"
                    className="btn btn-dark btn-sm"
                    onClick={() => {
                      if (webinar.title && webinar.link) {
                        formik.setFieldValue("webinars", [
                          ...formik.values.webinars,
                          { ...webinar },
                        ]);
                        setWebinar({ title: "", link: "", time: "" });
                      } else {
                        toast.error(
                          "Webinar title and link are required to add webinar"
                        );
                      }
                    }}
                  >
                    Add Webinar
                  </button>
                </div>
              </div>
            </div>
            <table className="table table-hover">
              <thead>
                <tr className="text-nowrap">
                  <th>Webinar Title</th>
                  <th>Webinar Time</th>
                  <th>Webinar Link</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {formik.values.webinars.length > 0 ? (
                  formik.values.webinars.map((o, i) => (
                    <tr key={`web-link${i}`}>
                      <td>{o.title}</td>
                      <td>{o.time}</td>
                      <td>{o.link}</td>
                      <td className="text-end">
                        <FiTrash2
                          className="like-btn"
                          size={20}
                          onClick={() => {
                            var temp_array = [...formik.values.webinars];
                            temp_array.splice(i, 1);
                            console.log(temp_array);
                            formik.setFieldValue("webinars", temp_array);
                          }}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={3}>No webinars added</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="part-section">
            <h6> Offering Highlights </h6>

            <div className="row">
              <div className="col-md-4">
                <div className="mb-2">
                  <label>Target Investor IRR</label>
                  <Form.Control
                    type="text"
                    size="sm"
                    value={formik.values.target_irr}
                    name="target_irr"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-2">
                  <label>Target Equity Multiple</label>
                  <Form.Control
                    type="text"
                    size="sm"
                    value={formik.values.target_equity_multiple}
                    name="target_equity_multiple"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="part-section">
            <h6> Project Informations </h6>
            <div className="row">
              {(() => {
                var hide = false;
                if (_type === "single") {
                  if (formik.values.properties.length > 0) {
                    hide = true;
                  }
                }

                if (!hide) {
                  return (
                    <>
                      <div className="col-md-4">
                        <div className="mb-2">
                          <label>Choose the Project</label>
                          <AsyncSelect
                            placeholder="Type to search project..."
                            cacheOptions
                            styles={crispStyle}
                            value={tempProperty}
                            defaultOptions={propertyList.map((o) => ({
                              label: o.name,
                              value: o._id,
                            }))}
                            loadOptions={loadPropertyListSearch}
                            onChange={(input) => {
                              setTempProperty(input);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <button
                          type="button"
                          className="btn btn-dark btn-sm"
                          onClick={handleAddProject}
                        >
                          Add Project
                        </button>
                      </div>
                    </>
                  );
                }
              })()}

              <div className="col-md-12">
                <PropertyGroupList
                  callbackValues={(data) => {
                    setTotals(data);
                  }}
                  propertyIds={formik.values.properties}
                  handleDeletePropertyGroup={(data) => {
                    formik.setFieldValue(
                      "properties",
                      formik.values.properties.filter((xx) => xx !== data)
                    );
                  }}
                />
              </div>
            </div>
          </div>

          <div className="part-section">
            <h6> Offering Description </h6>
            {/* <textarea
              className="form-control"
              rows={5}
              size="sm"
              value={formik.values.offering_description}
              name="offering_description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            ></textarea> */}

            <DefaultEditor
              placeholder="Test 2"
              value={formik.values.offering_description}
              onChange={(e) => {
                formik.setFieldValue("offering_description", e.target.value);
              }}
              title="ed1"
            />
          </div>
          <div className="part-section">
            <h6> Portfolio Images </h6>
            <BulkUpload
              callback={(input) => {
                formik.setFieldValue("listing_images", [
                  ...stateRef.current.listing_images,
                  ...input,
                ]);
              }}
              documentType={documentTypeIds.investor}
              dependsTo={documentDependIds.investor}
            />
            <DocumentList
              docs={formik.values.listing_images}
              deleteCallback={(_data) => {
                formik.setFieldValue(
                  "listing_images",
                  formik.values.listing_images.filter((xx) => xx !== _data)
                );
              }}
            />
          </div>

          <div className="part-section">
            <h6> Fund Documents </h6>
            <BulkUpload
              callback={(input) => {
                formik.setFieldValue("listing_documents", [
                  ...stateRef.current.listing_documents,
                  ...input,
                ]);
              }}
              documentType={documentTypeIds.investor}
              dependsTo={documentDependIds.investor}
            />
            <DocumentList
              docs={formik.values.listing_documents}
              deleteCallback={(_data) => {
                formik.setFieldValue(
                  "listing_documents",
                  formik.values.listing_documents.filter((xx) => xx !== _data)
                );
              }}
            />
          </div>

          <div className="part-section">
            <h6> Company Deck Documents </h6>
            <BulkUpload
              callback={(input) => {
                formik.setFieldValue("company_deck_documents", [
                  ...stateRef.current.company_deck_documents,
                  ...input,
                ]);
              }}
              documentType={documentTypeIds.investor}
              dependsTo={documentDependIds.investor}
            />
            <DocumentList
              docs={formik.values.company_deck_documents}
              deleteCallback={(_data) => {
                formik.setFieldValue(
                  "company_deck_documents",
                  formik.values.company_deck_documents.filter(
                    (xx) => xx !== _data
                  )
                );
              }}
            />
          </div>

          <div className="part-section">
            <h6> Portfolio Deck Documents 1 </h6>
            {/* <BulkUpload
              callback={(input) => {
                formik.setFieldValue("portfolio_deck_documents", [
                  ...stateRef.current.portfolio_deck_documents,
                  ...input,
                ]);
              }}
              documentType={documentTypeIds.investor}
              dependsTo={documentDependIds.investor}
            /> */}

            <BulkUpload
              callback={(input) => {
                formik.setFieldValue("portfolio_deck_documents", [
                  ...stateRef.current.portfolio_deck_documents,
                  ...input,
                ]);
              }}
              documentType={documentTypeIds.investor}
              dependsTo={documentDependIds.investor}
            />

            <DocumentList
              docs={formik.values.portfolio_deck_documents}
              deleteCallback={(_data) => {
                formik.setFieldValue(
                  "portfolio_deck_documents",
                  formik.values.portfolio_deck_documents.filter(
                    (xx) => xx !== _data
                  )
                );
              }}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default ListingForm;
