import React, { useState, useEffect } from "react";
import validator from "validator";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { useQuery } from "../hooks/url-params";
import { getCookies } from "../utils/cookies";
import { userLogoutThunk, userLoginThunk } from "../redux/thunk/user.thunk";
import { responseToastMsg } from "../utils/response-message";

const Login = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const location = useLocation();
  const query = useQuery(location.search);
  const navigate = useNavigate();
  const redirect = query.get("redirect");

  const [login, setLogin] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (user.login && getCookies()) {
      if (user.data.is_reset_password) {
        navigate("/create-password");
      } else {
        navigate("/dashboard");
        // if (redirect) {
        //   window.open(redirect, "_self");
        // } else {
        //   if (location.state?.from) {
        //     navigate(location.state?.from.pathname);
        //   } else {
        //     navigate("/dashboard");
        //   }
        // }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.login, navigate, location.state?.from, redirect]);

  useEffect(() => {
    if (!(user.login && getCookies())) {
      dispatch(userLogoutThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = async () => {
    const { email, password } = login;
    if (email && password) {
      if (!validator.isEmail(email)) {
        toast.error("Enter a valid email id");
        return false;
      }
      try {
        dispatch(userLoginThunk(login));
      } catch (error) {
        console.log(
          "🚀 ~ file: index.js ~ line 37 ~ handleSubmit ~ error",
          error
        );
        responseToastMsg(error.data.error_code);
      }
    } else {
      toast.error("Enter both email and password");
    }
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 pb-5">
      <div>
        <h3>Alamo Invest - Admin</h3>
        <div className="mb-2">
          <label>Email</label> <br />
          <input
            className="form-control"
            type="email"
            value={login.email}
            onKeyPress={(e) => handleEnter(e)}
            onChange={(e) =>
              setLogin({
                ...login,
                email: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <label>Password</label> <br />
          <input
            className="form-control"
            type="password"
            onKeyPress={(e) => handleEnter(e)}
            value={login.password}
            onChange={(e) => setLogin({ ...login, password: e.target.value })}
          />
        </div>
        <div>
          <button type="button" className="btn btn-dark" onClick={handleLogin}>
            Launch
          </button>
        </div>
        <div className="fs-tiny mt-2 text-secondary">
          Contact the administrator. If you forgot your password
        </div>
      </div>
    </div>
  );
};

export default Login;
