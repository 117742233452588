import React, { Suspense, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";

import Login from "./pages/login";
import CreatePassword from "./components/create-password";
import Logout from "./pages/logout";
import Home from "./pages/home";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getCookies } from "./utils/cookies";
import { userLogoutThunk } from "./redux/thunk/user.thunk";

import "./App.css";

let AuthContext = React.createContext(null);

function AuthProvider({ children }) {
  let [user, setUser] = React.useState(null);

  let signin = (newUser, callback) => {
    setUser(newUser);
    callback();
  };

  let signout = (callback) => {
    setUser(null);
    callback();
  };

  let value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}

function App() {
  return (
    <>
      <div className="top-loader">
        Loading....<span id="SpnUpload"></span>
      </div>
      <div className="whole-content">
        <BrowserRouter>
          <AuthProvider>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
                <Route
                  path="/create-password"
                  element={
                    <PrivateRoute>
                      <CreatePassword />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/:page_id"
                  element={
                    <PrivateRoute>
                      <Home />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/:page_id/:id"
                  element={
                    <PrivateRoute>
                      <Home />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/:page_id/:id/investing-accounts/:aid"
                  element={
                    <PrivateRoute>
                      <Home />
                    </PrivateRoute>
                  }
                />
                <Route path="*" element={<>404 - not found</>} />
              </Routes>
            </Suspense>
          </AuthProvider>
        </BrowserRouter>
      </div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          style: {
            fontSize: "13px",
            borderRadius: "3px",
          },
        }}
      />
    </>
  );
}

const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch();
  let location = useLocation();

  const { pathname } = location;

  const user = useSelector((state) => state.user);

  const token = getCookies();

  useEffect(() => {
    if (!token) {
      dispatch(userLogoutThunk());
    }
  }, [dispatch, token]);

  if (user.login) {
    if (!user.data.is_reset_password) {
      return children;
    } else {
      if (pathname !== "/create-password")
        return <Navigate to={{ pathname: "/create-password" }} replace />;
      else return children;
    }
  } else {
    return (
      <Navigate to={{ pathname: "/" }} state={{ from: location }} replace />
    );
  }
};

export default App;
