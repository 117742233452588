import React, { useCallback } from "react";
import request from "superagent";
import { useDropzone } from "react-dropzone";
import { BiCamera, BiDownload } from "react-icons/bi";
import { deleteMedia } from "../../client/methods";
import { responseToastMsg } from "../../utils/response-message";

import "./style.scss";

function ImageInput(props) {
  const onDrop = useCallback((acceptedFiles) => {
    handleUpload(acceptedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    maxFiles: 1,
  });

  const handleUpload = async (input) => {
    const media_urls = [];
    const url = process.env.REACT_APP_CLOUDINARY_URL;

    for (let file of input) {
      document.body.classList.add("loading-indicator");
      try {
        const response_x = await request
          .post(url)
          .field(
            "upload_preset",
            process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
          )
          .field("file", file)
          .field("folder", process.env.REACT_APP_CLOUDINARY_FOLDER)
          .field("multiple", true)
          .field("quality", process.env.REACT_APP_CLOUDINARY_QUALITY)
          .field("context", "photo=alamo-funding");

        media_urls.push({
          type: response_x.body.resource_type,
          public_id: response_x.body.public_id,
          url: response_x.body.secure_url,
        });
        document.body.classList.remove("loading-indicator");
      } catch (err) {
        document.body.classList.remove("loading-indicator");
        console.log(err);
      }
    }

    props.onChangeImage(media_urls[0]);
  };

  const handleDeleteImage = async () => {
    if (window.confirm("Are you sure to delete?")) {
      deleteMediaProcess(props.source);
    } else {
      return false;
    }
  };

  const deleteMediaProcess = async (input) => {
    try {
      const result = await deleteMedia(input);

      props.onChangeImage(null);

      responseToastMsg(result.data.response_code);
    } catch (error) {
      console.log(error);
      responseToastMsg(error.data.error_code);
    }
  };

  return (
    <>
      {props.source?.url ? (
        <img
          onClick={handleDeleteImage}
          src={props.source?.url}
          className="image-logo"
          alt="alamo-equity-images"
        />
      ) : (
        <div className="image-input-contianer" {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? <BiDownload size={25} /> : <BiCamera size={25} />}
        </div>
      )}
    </>
  );
}

export default ImageInput;
