import React from "react";
import { useParams } from "react-router-dom";

import InvestorsList from "./list";
import InvestorForm from "./form";

import "./style.scss";

const Investors = () => {
  const { id } = useParams();

  return <>{id ? <InvestorForm /> : <InvestorsList />}</>;
};

export default Investors;
