import React, { useEffect, useState } from "react";

import { getDocumentList } from "../../client/methods";
import { list_size } from "../../utils/common";
import { responseToastMsg } from "../../utils/response-message";
import { documentTypes } from "../../utils/options";
import { dateFormat } from "../../utils/common";
import { FiDownload } from "react-icons/fi";

const DocumentsList = () => {
  const [count, setCount] = useState(0);
  const [list, setList] = useState([]);
  const [loadHide, setLoadHide] = useState(false);

  useEffect(() => {
    getList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getList = async () => {
    try {
      const result = await getDocumentList(list_size, count);

      setList([...list, ...result.data.response]);
      setCount(count + result.data.response.length);
      if (result.data.response.length === 0) {
        setLoadHide(true);
      }
    } catch (error) {
      responseToastMsg(error.data.error_code);
    }
  };

  // const handleDelete = (e, input) => {
  //   e.preventDefault();
  //   e.stopPropagation();

  //   if (window.confirm("Are you sure to delete?")) {
  //     deleteProcess(input);
  //   } else {
  //     return false;
  //   }
  // };

  // const deleteProcess = async (input) => {
  //   try {
  //     const result = await deleteDocument(input);
  //     responseToastMsg(result.data.response_code);
  //     handleAction({ type: "delete", data: input });
  //   } catch (error) {
  //     responseToastMsg(error.data.error_code);
  //   }
  // };

  // const handleAction = ({ type, data }) => {
  //   if (type === "save") {
  //     const temp = [...list];
  //     temp.push(data);
  //     setList(temp);
  //   } else if (type === "update") {
  //     const temp = [...list];
  //     const index = temp.findIndex((o) => o._id === data._id);
  //     temp[index] = data;
  //     setList(temp);
  //   } else if (type === "delete") {
  //     setList(list.filter((o) => o._id !== data));
  //   }
  // };

  return (
    <>
      <div className="content-view">
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th> Name </th>
                <th> Type </th>
                <th> Upload On </th>
                <th className="text-center"> </th>
                {/* <th></th> */}
              </tr>
            </thead>
            <tbody>
              {list.map((obj, i) => (
                <tr
                  key={`ulist${i}`}
                  // onClick={() => navigate(`/documents/${obj._id}`)}
                  className="cursor-pointer"
                >
                  <td>{obj.document?.name}</td>
                  <td>
                    {" "}
                    {
                      documentTypes.find((o) => o.value === obj.document_type)
                        .label
                    }
                  </td>
                  <td>{dateFormat(obj.createdAt)}</td>
                  <td className="text-center">
                    <a
                      href={obj.document?.url}
                      target="_blank"
                      rel="noreferrer"
                      download
                      className="text-dark"
                    >
                      <FiDownload className="like-btn me-3" size={20} />
                    </a>
                  </td>
                  {/* <td>
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={(e) => handleDelete(e, obj._id)}
                    >
                      <BsTrash />
                    </button>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
          {!loadHide && (
            <div className="text-center mt-2">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={() => getList()}
              >
                Load more
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DocumentsList;
