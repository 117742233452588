import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import validator from "validator";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";

import InputPhone from "../input-phone";
import { createWebsite, getWebsite, updateWebsite } from "../../client/methods";
import { responseToastMsg } from "../../utils/response-message";
import { userSchema } from "../../utils/validation-schema";
import { FaArrowLeft, FaSave } from "react-icons/fa";

import "./style.scss";
import { FiTrash2 } from "react-icons/fi";
import DocumentUpload from "../document-upload";
import { documentDependIds, documentTypeIds } from "../../utils/options";
import DocumentList from "../document-list/index";

const WebsiteForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [spec, setSpec] = useState({
    title: "",
    desc: "",
  });
  const [gallery, setGallery] = useState({
    active: false,
    title: "",
    images: [],
  });
  const [pstatus, setPstatus] = useState({
    active: false,
    title: "",
    images: [],
  });

  const stateRef = useRef();
  const currentRef = useRef();
  const statusRef = useRef();

  const init = {
    page_id: "",
    project_name: "",
    address: "",
    city: "",
    state: "",
    zip_code: "",
    google_pin_link: "",
    banner_image: [],
    project_details: "",
    location_details: "",
    project_specs: [],
    floor_plans: [],
    gallery: [],
    project_status: [],
  };

  const onSubmit = async (values) => {
    const { project_name } = values;

    if (project_name) {
      try {
        if (id && id !== "new") {
          const result = await updateWebsite(id, values);

          responseToastMsg(result.data.response_code);
        } else {
          const result = await createWebsite(values);

          responseToastMsg(result.data.response_code);
          navigate("/websites");
        }
      } catch (error) {
        responseToastMsg(error.data.error_code);
        console.log(
          "🚀 ~ file: index.js ~ line 59 ~ handleSave ~ error",
          error
        );
      }
    } else {
      toast.error("Fill all the mandatory * fields");
    }
  };

  const formik = useFormik({
    initialValues: init,
    // validationSchema: userSchema,
    onSubmit,
  });

  stateRef.current = formik.values;
  currentRef.current = gallery;
  statusRef.current = pstatus;

  useEffect(() => {
    if (id === "new") {
      formik.setValues(init);
    } else {
      id && handleGetUser(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleGetUser = async (input) => {
    try {
      const result = await getWebsite(input);

      formik.setValues({ ...result.data.response });
    } catch (error) {
      responseToastMsg(error.data.error_code);
      console.log(
        "🚀 ~ file: index.js ~ line 36 ~ handleGetUser ~ error",
        error
      );
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="pb-2 sticky-top mb-2 bg-white bottom-shadow">
          <Link to={"/websites"} className="btn btn-dark btn-sm ps-3 pe-3 me-2">
            <FaArrowLeft /> Back to Websites List
          </Link>
          <button
            type="submit"
            className="btn btn-success btn-sm ps-3 pe-3 me-2"
          >
            <FaSave /> {id === "new" ? "Save" : "Update"}
          </button>
        </div>
        <div className="container-fluid">
          <div className="part-section">
            <h6> Basic Informations </h6>
            <div className="row">
              <div className="col-md-4">
                <div className="mb-2">
                  <label>Page Id*</label>
                  <Form.Control
                    type="text"
                    placeholder="Project Name"
                    size="sm"
                    value={formik.values.page_id}
                    name="page_id"
                    onChange={(e) =>
                      formik.setFieldValue(
                        "page_id",
                        e.target.value.toLowerCase().replace(/ /g, "")
                      )
                    }
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-8"></div>
              <div className="col-md-4">
                <div className="mb-2">
                  <label>Project Name*</label>
                  <Form.Control
                    type="text"
                    placeholder="Project Name"
                    size="sm"
                    value={formik.values.project_name}
                    name="project_name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-2">
                  <label>Address</label>
                  <Form.Control
                    type="text"
                    placeholder="Address"
                    size="sm"
                    value={formik.values.address}
                    name="address"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-2">
                  <label>City</label>
                  <Form.Control
                    type="text"
                    placeholder="City"
                    size="sm"
                    value={formik.values.city}
                    name="city"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-2">
                  <label>State</label>
                  <Form.Control
                    type="text"
                    placeholder="State"
                    size="sm"
                    value={formik.values.state}
                    name="state"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-2">
                  <label>ZIP Code</label>
                  <Form.Control
                    type="text"
                    placeholder="Zip Code"
                    size="sm"
                    value={formik.values.zip_code}
                    name="zip_code"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-2">
                  <label>Google Pin Link</label>
                  <Form.Control
                    type="text"
                    placeholder="Google Pin Link"
                    size="sm"
                    value={formik.values.google_pin_link}
                    name="google_pin_link"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="part-section">
            <h6> Banner Image </h6>
            {formik.values.banner_image.length < 1 && (
              <DocumentUpload
                callback={(input) => {
                  formik.setFieldValue("banner_image", [
                    ...stateRef.current.banner_image,
                    ...input,
                  ]);
                }}
                documentType={documentTypeIds.investor}
                dependsTo={documentDependIds.investor}
              />
            )}

            <DocumentList
              docs={formik.values.banner_image}
              deleteCallback={(_data) => {
                formik.setFieldValue(
                  "banner_image",
                  formik.values.banner_image.filter((xx) => xx !== _data)
                );
              }}
            />
          </div>

          <div className="part-section">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-2">
                  <label>Project Synopsis</label>
                  <textarea
                    rows={5}
                    className="form-control"
                    type="text"
                    placeholder="Project Synopsis"
                    size="sm"
                    value={formik.values.project_details}
                    name="project_details"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-2">
                  <label>Location Details</label>
                  <textarea
                    rows={5}
                    className="form-control"
                    type="text"
                    placeholder="Location Details"
                    size="sm"
                    value={formik.values.location_details}
                    name="location_details"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="part-section">
            <h6>Project Specifications</h6>
            <div className="row">
              <div className="col-md-4">
                <div className="mb-2">
                  <label>Title</label>
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    size="sm"
                    value={spec.title}
                    onChange={(e) =>
                      setSpec({ ...spec, title: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-2">
                  <label>Description</label>
                  <Form.Control
                    type="text"
                    placeholder="Descriptions"
                    size="sm"
                    value={spec.desc}
                    onChange={(e) => setSpec({ ...spec, desc: e.target.value })}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-2">
                  <button
                    type="button"
                    className="btn btn-dark btn-sm ps-4 pe-4"
                    onClick={() => {
                      const { title, desc } = spec;

                      if (title && desc) {
                        formik.setFieldValue("projects_specs", [
                          ...formik.values.projects_specs,
                          spec,
                        ]);
                        setSpec({
                          title: "",
                          desc: "",
                        });
                      } else {
                        toast.error("Fill both Title and Description Feilds");
                      }
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
              <div className="col-md-12">
                <hr />
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Description</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {formik.values?.projects_specs?.map((o, i) => (
                      <tr key={`row_proj_spec${i}`}>
                        <td>{o.title}</td>
                        <td>{o.desc}</td>
                        <td>
                          <FiTrash2
                            className="like-btn"
                            size={20}
                            onClick={() => {
                              var _info = [];
                              for (
                                var x = 0;
                                x < formik.values.projects_specs.length;
                                x++
                              ) {
                                if (x !== i)
                                  _info.push(formik.values.projects_specs[x]);
                              }
                              formik.setFieldValue("projects_specs", _info);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div> */}
          <div className="part-section">
            <h6> Project Specifications </h6>
            <DocumentUpload
              callback={(input) => {
                formik.setFieldValue("project_specs", [
                  ...stateRef.current.project_specs,
                  ...input,
                ]);
              }}
              documentType={documentTypeIds.investor}
              dependsTo={documentDependIds.investor}
            />
            <DocumentList
              docs={formik.values.project_specs}
              deleteCallback={(_data) => {
                formik.setFieldValue(
                  "project_specs",
                  formik.values.project_specs.filter((xx) => xx !== _data)
                );
              }}
            />
          </div>
          <div className="part-section">
            <h6> Floor Plans </h6>
            <DocumentUpload
              callback={(input) => {
                formik.setFieldValue("floor_plans", [
                  ...stateRef.current.floor_plans,
                  ...input,
                ]);
              }}
              documentType={documentTypeIds.investor}
              dependsTo={documentDependIds.investor}
            />
            <DocumentList
              docs={formik.values.floor_plans}
              deleteCallback={(_data) => {
                formik.setFieldValue(
                  "floor_plans",
                  formik.values.floor_plans.filter((xx) => xx !== _data)
                );
              }}
            />
          </div>
          <div className="part-section">
            <h6> Gallery </h6>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-2">
                  <label>Images</label>

                  <DocumentUpload
                    callback={(input) => {
                      setGallery({
                        ...gallery,
                        images: [...currentRef.current.images, ...input],
                      });
                    }}
                    documentType={documentTypeIds.investor}
                    dependsTo={documentDependIds.investor}
                  />
                  <DocumentList
                    docs={gallery.images}
                    deleteCallback={(_data) => {
                      setGallery({
                        ...gallery,
                        images: gallery.images.filter((xx) => xx !== _data),
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-2">
                  <label>Title</label>
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    size="sm"
                    value={gallery.title}
                    onChange={(e) =>
                      setGallery({ ...gallery, title: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-2">
                  <button
                    type="button"
                    className="btn btn-dark btn-sm ps-4 pe-4"
                    onClick={() => {
                      const { title, images } = gallery;
                      if (title && images.length > 0) {
                        if (formik.values.gallery.length === 0) {
                          formik.setFieldValue("gallery", [
                            ...formik.values.gallery,
                            { ...gallery, active: true },
                          ]);
                        } else {
                          formik.setFieldValue("gallery", [
                            ...formik.values.gallery,
                            gallery,
                          ]);
                        }

                        setGallery({
                          title: "",
                          images: [],
                        });
                      } else {
                        toast.error("Fill the Title and One image is required");
                      }
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
              <div className="col-md-12">
                <hr />
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>No Of Images</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {formik.values?.gallery?.map((o, i) => (
                      <tr key={`row_proj_spec${i}`}>
                        <td>{o.title}</td>
                        <td>{o.images.length}</td>
                        <td>
                          <FiTrash2
                            className="like-btn"
                            size={20}
                            onClick={() => {
                              var _info = [];
                              for (
                                var x = 0;
                                x < formik.values.gallery.length;
                                x++
                              ) {
                                if (x !== i)
                                  _info.push(formik.values.gallery[x]);
                              }
                              if (_info.length > 0) _info[0].active = true;
                              formik.setFieldValue("gallery", _info);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="part-section">
            <h6> Project Status </h6>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-2">
                  <label>Images</label>

                  <DocumentUpload
                    maxFiles={100}
                    callback={(c_docs) => {
                      setPstatus({
                        ...pstatus,
                        images: [...statusRef.current.images, ...c_docs],
                      });
                    }}
                    documentType={documentTypeIds.investor}
                    dependsTo={documentDependIds.investor}
                  />
                  <DocumentList
                    docs={pstatus.images}
                    deleteCallback={(_data) => {
                      setPstatus({
                        ...pstatus,
                        images: pstatus.images.filter((xx) => xx !== _data),
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-2">
                  <label>Title</label>
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    size="sm"
                    value={pstatus.title}
                    onChange={(e) =>
                      setPstatus({ ...pstatus, title: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-2">
                  <button
                    type="button"
                    className="btn btn-dark btn-sm ps-4 pe-4"
                    onClick={() => {
                      const { title, images } = pstatus;

                      if (title && images.length > 0) {
                        if (formik.values.project_status.length === 0) {
                          formik.setFieldValue("project_status", [
                            ...formik.values.project_status,
                            { ...pstatus, active: true },
                          ]);
                        } else {
                          formik.setFieldValue("project_status", [
                            ...formik.values.project_status,
                            pstatus,
                          ]);
                        }

                        setPstatus({
                          title: "",
                          images: [],
                        });
                      } else {
                        toast.error("Fill the Title and One image is required");
                      }
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
              <div className="col-md-12">
                <hr />
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>No Of Images</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {formik.values?.project_status?.map((o, i) => (
                      <tr key={`row_proj_spec${i}`}>
                        <td>{o.title}</td>
                        <td>{o.images.length}</td>
                        <td>
                          <FiTrash2
                            className="like-btn"
                            size={20}
                            onClick={() => {
                              var _info = [];
                              for (
                                var x = 0;
                                x < formik.values.project_status.length;
                                x++
                              ) {
                                if (x !== i)
                                  _info.push(formik.values.project_status[x]);
                              }
                              if (_info.length > 0) _info[0].active = true;
                              formik.setFieldValue("project_status", _info);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default WebsiteForm;
