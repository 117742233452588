import React from "react";
import { useParams } from "react-router-dom";

import WebsiteForm from "./form";
import WebsiteList from "./list";

import "./style.scss";

const Websites = () => {
  const { id } = useParams();
  return <>{id ? <WebsiteForm /> : <WebsiteList />}</>;
};

export default Websites;
