import React from "react";
import Navbar from "../navbar";
import PageHeader from "../page-header";
import { useParams } from "react-router-dom";

import "./style.scss";

const Page = ({ children }) => {
  const { page_id } = useParams();

  return (
    <div className="app-page">
      <Navbar />

      <div className="page-content">
        <PageHeader id={page_id} />

        <div className="content-area">{children}</div>
      </div>
    </div>
  );
};

export default Page;
