import * as yup from "yup";

export const userSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(5, "Name must be at least 5 Characters long")
    .lowercase()
    .required("Name Required"),

  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email Required"),
});

export const emailSchema = yup.object().shape({
  subject: yup.string().trim().lowercase().required("Subject Required"),
  message: yup
    .string()
    .trim()
    .min(5, "Message must be at least 5 Characters long")
    .lowercase()
    .required("Message Required"),
});

export const propertySchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(5, "Name must be at least 5 Characters long")
    .lowercase()
    .required("Name Required"),
});

export const contactGroupSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(5, "Name must be at least 5 Characters long")
    .lowercase()
    .required("Group Name Required"),
});

export const investorSchema = yup.object().shape({
  first_name: yup
    .string()
    .trim()
    .lowercase()
    .min(1, "Name must be at least 5 Characters long")
    .required("First Name Required"),
  last_name: yup
    .string()
    .trim()
    .lowercase()
    .min(1, "Name must be at least 5 Characters long")
    .required("Last Name Required"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email Required"),
});

export const changePasswordSchema = yup.object().shape({
  old_password: yup
    .string()
    .min(8, "Enter a correct password")
    .required("Old Password required"),

  new_password: yup
    .string()
    .min(8, "Password must be 8 characters long")
    .required("New Password Required"),

  confirm_password: yup
    .string()
    .oneOf([yup.ref("new_password"), null], "Password must match")
    .required("Required"),
});

export const createPasswordSchema = yup.object().shape({
  create_new_password: yup
    .string()
    .min(8, "Password must be 8 characters long")
    .required("New Password Required"),

  create_confirm_password: yup
    .string()
    .oneOf([yup.ref("create_new_password"), null], "Password must match")
    .required("Required"),
});
