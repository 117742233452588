import React from "react";
import { Form } from "react-bootstrap";
import { numberFormatter } from "../../utils/common";

import "./style.scss";

const AppDollarInput = (props) => {
  return (
    <div className="app-dollar-input">
      <Form.Control {...props} />
      <span className="fs-tiny">
        {" "}
        ~ {numberFormatter(props.value, props.decimal)}
      </span>
    </div>
  );
};

export default AppDollarInput;
