import React, { useEffect, useState, useRef } from "react";
import toast from "react-hot-toast";
import validator from "validator";
import Toggle from "react-toggle";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { FaArrowLeft, FaSave } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { BiReset } from "react-icons/bi";
import { useSelector } from "react-redux";

import ImageInput from "../image-input/index";
import {
  createUser,
  resendInvite,
  getUser,
  updateUser,
  resetPasswordAdmin,
} from "../../client/methods";
import { responseToastMsg } from "../../utils/response-message";
import { userSchema } from "../../utils/validation-schema";

import "./style.scss";

const UserForm = () => {
  const { data: storeUser } = useSelector((state) => state.user);

  const navigate = useNavigate();
  const { id } = useParams();
  const [show, setShow] = useState();

  const init = {
    name: "",
    email: "",
    user_image: "",
    is_active: true,
  };

  const stateRef = useRef();

  const onSubmit = async (values) => {
    const { name, email } = values;

    if (name && email) {
      if (!validator.isEmail(email)) {
        toast.error("Enter a valid email id");
        return false;
      }

      try {
        if (id && id !== "new") {
          const result = await updateUser(id, values);

          responseToastMsg(result.data.response_code);
        } else {
          const result = await createUser(values);

          responseToastMsg(result.data.response_code);
        }
        navigate("/users");
      } catch (error) {
        responseToastMsg(error.data.error_code);
        console.log(
          "🚀 ~ file: index.js ~ line 59 ~ handleSave ~ error",
          error
        );
      }
    } else {
      toast.error("Fill all the mandatory * fields");
    }
  };

  const formik = useFormik({
    initialValues: init,
    validationSchema: userSchema,
    onSubmit,
  });

  stateRef.current = formik.values;

  useEffect(() => {
    if (id === "new") {
      formik.setValues(init);
    } else {
      id && handleGetUser(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleGetUser = async (input) => {
    try {
      const result = await getUser(input);

      formik.setValues({ ...result.data.response });
    } catch (error) {
      responseToastMsg(error.data.error_code);
      console.log(
        "🚀 ~ file: index.js ~ line 36 ~ handleGetUser ~ error",
        error
      );
    }
  };

  const handleResetPassword = async () => {
    try {
      const result = await resetPasswordAdmin({
        id: id,
      });
      responseToastMsg(result.data.response_code);
      navigate("/users");
    } catch (error) {
      responseToastMsg(error.data.error_code);
    }
  };

  const handleImageChange = (input) => {
    formik.setValues({
      ...stateRef.current,
      user_image: input,
    });
  };

  const handleResendInvite = async () => {
    try {
      const result = await resendInvite(id);
      responseToastMsg(result.data.response_code);
    } catch (error) {
      responseToastMsg(error.data.error_code);
    }
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="pb-2 sticky-top mb-2 bg-white bottom-shadow">
          <Link to={"/users"} className="btn btn-dark btn-sm ps-3 pe-3 me-2">
            <FaArrowLeft /> Back to Users List
          </Link>
          <button className="btn btn-success btn-sm ps-3 pe-3 me-2">
            <FaSave /> {id === "new" ? "Save" : "Update"}
          </button>
          {id !== "new" && storeUser.is_admin && (
            <button
              className="btn btn-danger ml-3 ps-3 pe-3 btn-sm"
              type="button"
              onClick={() => setShow(true)}
            >
              <BiReset /> Reset Password
            </button>
          )}
        </div>
        <div className="container-fluid">
          <h6> Personal info </h6>
          <div className="row">
            <div className="col-md-4">
              <div className="mb-2">
                <label>Name*</label>
                <Form.Control
                  type="text"
                  placeholder="First Name - Last Name"
                  size="sm"
                  value={formik.values.name}
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={
                    formik.touched.name && formik.errors.name
                      ? "is-invalid"
                      : !formik.errors.name && "is-valid"
                  }
                />
                {formik.errors.name && formik.touched.name && (
                  <p className="text-danger">{formik.errors.name}</p>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-2">
                <label>Email*</label>
                <Form.Control
                  type="text"
                  placeholder="Email"
                  size="sm"
                  value={formik.values.email}
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={
                    formik.touched.email && formik.errors.email
                      ? "is-invalid"
                      : !formik.errors.email && "is-valid"
                  }
                />
                {formik.errors.email && formik.touched.email && (
                  <p className="text-danger">{formik.errors.email}</p>
                )}
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-2">
                <label>Active</label> <br />
                <Toggle
                  disabled={
                    storeUser.is_admin &&
                    storeUser.user_id === formik.values._id
                  }
                  className="mt-2"
                  checked={formik.values.is_active}
                  name="is_active"
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-2">
                <label>Profile Photo</label> <br />
                <ImageInput
                  source={formik.values.user_image}
                  onChangeImage={handleImageChange}
                />
              </div>
            </div>
          </div>
        </div>

        {id === "new" ? (
          <div className="fs-little">
            Note: A invite link will be send to the new user's email. User can
            accept the invite and login with the default password "welcome123"
          </div>
        ) : (
          formik.values?.accept_invite !== undefined && (
            <div className="fs-little">
              {!formik.values?.accept_invite &&
                `A invite link has been sent to the new user's email. User can
          accept the invite and login with the default password "welcome123"`}
              <br />
              {!formik.values?.accept_invite && (
                <button
                  className="btn btn-dark ml-3 ps-3 pe-3 btn-sm mt-2"
                  type="button"
                  onClick={() => handleResendInvite()}
                >
                  <FiMail /> Resend invite
                </button>
              )}
            </div>
          )
        )}
      </form>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        size={"md"}
        backdrop={"static"}
        style={{ backdropFilter: "blur(1px)" }}
      >
        <Modal.Header closeButton>
          Are you sure you want to reset the password
        </Modal.Header>
        <Modal.Body>
          <div className="fs-little mb-3">
            The default password will be used for this account. <br /> Default
            password is "welcome123"
          </div>
          <div className="text-end">
            <button
              className="btn btn-outline-secondary ps-3 pe-3 btn-sm me-2"
              onClick={() => setShow(false)}
            >
              Cancel
            </button>
            <button
              className="btn btn-dark btn-sm ps-3 pe-3"
              onClick={handleResetPassword}
            >
              Confirm
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserForm;
