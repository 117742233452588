import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  FiPieChart,
  FiShare2,
  FiMail,
  FiUsers,
  FiUserCheck,
  FiGrid,
} from "react-icons/fi";
import { BsFileEarmarkMedical } from "react-icons/bs";
import {
  FaHeart,
  FaIdCard,
  FaMoneyBillWaveAlt,
  FaPowerOff,
  FaRegBuilding,
  FaUserCog,
} from "react-icons/fa";
import { MdWeb } from "react-icons/md";

import "./style.scss";

const SideBar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleNavigate = (input) => {
    navigate(input);
  };

  return (
    <div className="side-bar">
      <div
        className={`side-menu-item ${
          pathname.includes("/dashboard") ? "active" : null
        }`}
        onClick={() => handleNavigate("/dashboard")}
      >
        <FiPieChart />
        <span className="side-menu-text">Dashboard</span>
      </div>
      <div
        className={`side-menu-item ${
          pathname.includes("/listings") ? "active" : null
        }`}
        onClick={() => handleNavigate("/listings")}
      >
        <FiGrid />
        <span className="side-menu-text">Listings</span>
      </div>
      <div
        className={`side-menu-item ${
          pathname.includes("/properties") ? "active" : null
        }`}
        onClick={() => handleNavigate("/properties")}
      >
        <FaRegBuilding />
        <span className="side-menu-text">Property/Project</span>
      </div>
      <div
        className={`side-menu-item ${
          pathname.includes("/interest") ? "active" : null
        }`}
        onClick={() => handleNavigate("/interest")}
      >
        <FaHeart />
        <span className="side-menu-text">Interest</span>
      </div>
      <div
        className={`side-menu-item ${
          pathname.includes("/investments") ? "active" : null
        }`}
        onClick={() => handleNavigate("/investments")}
      >
        <FaMoneyBillWaveAlt />
        <span className="side-menu-text">Investments</span>
      </div>
      <div
        className={`side-menu-item ${
          pathname.includes("/distributions") ? "active" : null
        }`}
        onClick={() => handleNavigate("/distributions")}
      >
        <FiShare2 />
        <span className="side-menu-text">Distributions</span>
      </div>
      <div
        className={`side-menu-item ${
          pathname.includes("/documents") ? "active" : null
        }`}
        onClick={() => handleNavigate("/documents")}
      >
        <BsFileEarmarkMedical />
        <span className="side-menu-text">Documents</span>
      </div>
      <div
        className={`side-menu-item ${
          pathname.includes("/contacts") ? "active" : null
        }`}
        onClick={() => handleNavigate("/contacts")}
      >
        <FaIdCard />
        <span className="side-menu-text">Contacts</span>
      </div>
      <div
        className={`side-menu-item ${
          pathname.includes("/contact-groups") ? "active" : null
        }`}
        onClick={() => handleNavigate("/contact-groups")}
      >
        <FiUsers />
        <span className="side-menu-text">Contact Groups</span>
      </div>
      <div
        className={`side-menu-item ${
          pathname.includes("/emails") ? "active" : null
        }`}
        onClick={() => handleNavigate("/emails")}
      >
        <FiMail />
        <span className="side-menu-text">Emails</span>
      </div>
      <div
        className={`side-menu-item ${
          pathname.includes("/investors") ? "active" : null
        }`}
        onClick={() => handleNavigate("/investors")}
      >
        <FiUserCheck />
        <span className="side-menu-text">Investors</span>
      </div>
      <div
        className={`side-menu-item ${
          pathname.includes("/websites") ? "active" : null
        }`}
        onClick={() => handleNavigate("/websites")}
      >
        <MdWeb />
        <span className="side-menu-text">Websites</span>
      </div>
      <div
        className={`side-menu-item ${
          pathname.includes("/users") ? "active" : null
        }`}
        onClick={() => handleNavigate("/users")}
      >
        <FaUserCog />
        <span className="side-menu-text">Users</span>
      </div>
      <div
        className={`side-menu-item ${
          pathname.includes("/logout") ? "active" : null
        }`}
        onClick={() => handleNavigate("/logout")}
      >
        <FaPowerOff />
        <span className="side-menu-text">Logout</span>
      </div>
    </div>
  );
};

export default SideBar;
