import React, { useState, useEffect } from "react";
import { FiTrash2 } from "react-icons/fi";
import { getMultiProperty } from "../../client/methods";
import { numberFormatter } from "../../utils/common";

const PropertyGroupList = ({
  propertyIds = [],
  handleDeletePropertyGroup = () => {},
  callbackValues = () => {},
}) => {
  const [list, setList] = useState([]);

  var _totals = {
    estimated_total_cost: 0,
    total_equity: 0,
    target_raise: 0,
    total_debts: 0,
    total_units: 0,
    total_dollar_per_unit: 0,
    total_no_of_buildings: 0,
  };

  useEffect(() => {
    if (propertyIds.length > 0) getPropertyGroups(propertyIds);
    else {
      setList([]);

      callbackValues({
        ..._totals,
      });
    }
  }, [propertyIds]);

  const getPropertyGroups = async (input) => {
    try {
      const result = await getMultiProperty(input);
      const res_list = result.data.response;

      for (var x = 0; x < res_list.length; x++) {
        _totals = {
          estimated_total_cost:
            _totals.estimated_total_cost + res_list[x].project_cost,
          total_equity: _totals.total_equity + res_list[x].project_equity,
          target_raise: _totals.total_equity + res_list[x].project_equity,
          total_debts: _totals.total_debts + res_list[x].project_debt,
          total_units: _totals.total_units + res_list[x].units,
          total_dollar_per_unit:
            _totals.total_dollar_per_unit + res_list[x].dollar_per_unit,
          total_no_of_buildings:
            _totals.total_no_of_buildings + res_list[x].no_of_buildings,
        };
      }
      callbackValues({
        ..._totals,
        total_dollar_per_unit: _totals.total_dollar_per_unit / res_list.length,
      });
      setList(res_list);
    } catch (error) {
      console.log("🚀 ~ file: index.js:16 ~ getPropertyGroups ~ error", error);
    }
  };

  if (list.length > 0)
    return (
      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Project Name</th>
              <th>Project Cost</th>
              <th>Equity</th>

              <th></th>
            </tr>
          </thead>
          <tbody>
            {list.map((obj, i) => (
              <tr key={`contact_list_${i}`}>
                <td>{obj.name}</td>
                <td>{numberFormatter(obj.project_cost)}</td>
                <td>{numberFormatter(obj.project_equity)}</td>
                <td className="text-end">
                  <FiTrash2
                    className="like-btn"
                    size={20}
                    onClick={() => handleDeletePropertyGroup(obj._id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  else return null;
};

export default PropertyGroupList;
