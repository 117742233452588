import React from "react";
import { useParams } from "react-router-dom";

import ContactGroupsList from "./list";
import ContactGroupForm from "./form";

import "./style.scss";

const ContactGroups = () => {
  const { id } = useParams();

  return <>{id ? <ContactGroupForm /> : <ContactGroupsList />}</>;
};

export default ContactGroups;
