import React, { useEffect, useRef } from "react";

import Select from "react-select";
import toast from "react-hot-toast";
import { Form } from "react-bootstrap";

import Toggle from "react-toggle";
import InputMask from "react-input-mask";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import {
  createInvestingAccounts,
  getInvestingAccounts,
  updateInvestingAccounts,
} from "../../client/methods";
import { responseToastMsg } from "../../utils/response-message";

import { userSchema } from "../../utils/validation-schema";
import { FaSave } from "react-icons/fa";
import DocumentUpload from "../document-upload";
import DocumentList from "../document-list";
import countries from "../../utils/countries.json";
import { crispStyle } from "../../utils/common";

import {
  supportingMemberOptions,
  CREInvestingExperienceRadioOptions,
  approximateNetWorthOptions,
  CREInvestingExperienceCheckOptions,
  generalInvestingExperienceOptions,
  goalsRealEstateInvestmentsOptions,
  signatoryStatusOptions,
  accountTypesOptions,
  documentTypeIds,
  documentDependIds,
} from "../../utils/options";
import { preferredDistributionMethodOptions } from "./../../utils/options";

import "./style.scss";

const InvestingAccounts = () => {
  const navigate = useNavigate();
  const { aid, id } = useParams();

  const init = {
    investor_id: id,
    account_type: "",
    legal_investing_account_name: "",
    ssn: "",
    tax_rate: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "USA",

    account_members: {
      supporting_member: "",
      first_name: "",
      last_name: "",
      signatory_status: [],
      email: "",
    },
    account_suitability: {
      goal_for_investing_REI: [],
      general_investing_experience: [],
      approximate_net_worth: "",
      CRE_investing_experience_ratio: "",
      CRE_investing_experience_check: [],
      hold_periods_over_3_years: false,
      is_qualified_purchaser: false,
      is_moderately_aggresive_investment: false,
    },
    accreditation: {
      accreditation_documents: [],
    },

    distribution_details: {
      preferred_distribution_method: "",
      financial_instruction_name: "",
      routing_number: "",
      financial_instruction_address: "",
      financial_instruction_phone_number: "",
      account_type: "checking",
      beneficiary_account_name: "",
      beneficiary_account_number: "",
    },
  };

  const stateRef = useRef();

  const onSubmit = async (values) => {
    const { legal_investing_account_name } = values;

    if (legal_investing_account_name) {
      try {
        if (aid && aid !== "new") {
          const result = await updateInvestingAccounts(aid, values);

          responseToastMsg(result.data.response_code);
        } else {
          const result = await createInvestingAccounts(values);

          responseToastMsg(result.data.response_code);
        }
        navigate(`/investors/${id}`);
      } catch (error) {
        responseToastMsg(error.data.error_code);
        console.log(
          "🚀 ~ file: index.js ~ line 59 ~ handleSave ~ error",
          error
        );
      }
    } else {
      toast.error("Fill all the mandatory * fields");
    }
  };

  const formik = useFormik({
    initialValues: init,
    // validationSchema: userSchema,
    onSubmit,
  });

  stateRef.current = formik.values;

  useEffect(() => {
    if (aid === "new") {
      formik.setValues(init);
    } else {
      aid && handleGetInvestingAccounts(aid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aid]);

  const handleGetInvestingAccounts = async (input) => {
    try {
      const result = await getInvestingAccounts(input);

      formik.setValues({ ...result.data.response });
    } catch (error) {
      responseToastMsg(error.data.error_code);
      console.log(
        "🚀 ~ file: index.js ~ line 36 ~ handleGetUser ~ error",
        error
      );
    }
  };

  const handleGoalForInvestingREI = (e, input) => {
    if (e.target.checked) {
      formik.setFieldValue("account_suitability.goal_for_investing_REI", [
        ...formik.values?.account_suitability?.goal_for_investing_REI,
        input,
      ]);
    } else {
      formik.setFieldValue(
        "account_suitability.goal_for_investing_REI",
        formik.values?.account_suitability?.goal_for_investing_REI.filter(
          (o) => o !== input
        )
      );
    }
  };
  const handleGeneralInvestingExperience = (e, input) => {
    if (e.target.checked) {
      formik.setFieldValue("account_suitability.general_investing_experience", [
        ...formik.values?.account_suitability?.general_investing_experience,
        input,
      ]);
    } else {
      formik.setFieldValue(
        "account_suitability.general_investing_experience",
        formik.values?.account_suitability?.general_investing_experience.filter(
          (o) => o !== input
        )
      );
    }
  };

  //   const handleGeneralInvestingExperience = (e, input) => {
  //     if (e.target.checked) {
  //       formik.setFieldValue("account_suitability.general_investing_experience", [
  //         ...formik.values?.account_suitability?.general_investing_experience,
  //         input,
  //       ]);
  //     } else {
  //       formik.setFieldValue(
  //         "account_suitability.general_investing_experience",
  //         formik.values?.account_suitability?.general_investing_experience.filter(
  //           (o) => o !== input
  //         )
  //       );
  //     }
  //   };
  const handleCREInvestingExperienceCheck = (e, input) => {
    if (e.target.checked) {
      formik.setFieldValue(
        "account_suitability.CRE_investing_experience_check",
        [
          ...formik.values?.account_suitability?.CRE_investing_experience_check,
          input,
        ]
      );
    } else {
      formik.setFieldValue(
        "account_suitability.CRE_investing_experience_check",
        formik.values?.account_suitability?.CRE_investing_experience_check.filter(
          (o) => o !== input
        )
      );
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="pb-2 sticky-top mb-2 bg-white bottom-shadow">
        {/* <Link to={"/investing-accounts"} className="btn btn-dark btn-sm ps-3 pe-3 me-2">
          <FaArrowLeft /> Back to Investing Accounts List
        </Link> */}
        <button className="btn btn-success btn-sm ps-3 pe-3 me-2" type="submit">
          <FaSave /> {aid === "new" ? "Save" : "Update"}
        </button>
      </div>
      <div className="part-section">
        <h6> Account details </h6>
        <div className="part-section">
          <div className="container-fluid">
            <h6> Account info </h6>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-2">
                  <label>Account Type</label>
                  <Form.Control
                    type="text"
                    placeholder="Account Type"
                    size="sm"
                    value={formik.values.account_type}
                    name="account_type"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-2">
                  <label>Legal Investing Account Name</label>
                  <Form.Control
                    placeholder="Legal Investing Account Name"
                    size="sm"
                    value={formik.values.legal_investing_account_name}
                    name="legal_investing_account_name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="part-section">
          <div className="container-fluid">
            <h6> Taxpayer details </h6>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-2">
                  <label>SSN or EIN</label>
                  <InputMask
                    className="form-control"
                    mask="999-99-9999"
                    maskChar="_"
                    value={formik.values.ssn}
                    name="ssn"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-2">
                  <label>Tax rate</label>
                  <Form.Control
                    placeholder="Tax rate"
                    size="sm"
                    value={formik.values.tax_rate}
                    name="tax_rate"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="part-section">
          <div className="container-fluid">
            <h6> Account mailing address </h6>
            <label>PO boxes are not accepted</label>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-2">
                  <label>Address</label>
                  <Form.Control
                    type="text"
                    placeholder="Address"
                    size="sm"
                    value={formik.values.address}
                    name="address"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-2">
                  <label>City</label>
                  <Form.Control
                    placeholder="City"
                    size="sm"
                    value={formik.values.city}
                    name="city"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-2">
                  <label>State</label>
                  <Form.Control
                    type="text"
                    placeholder="State"
                    size="sm"
                    value={formik.values.state}
                    name="state"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-2">
                  <label>Zip</label>
                  <Form.Control
                    placeholder="Zip"
                    size="sm"
                    value={formik.values.zip}
                    name="zip"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-2">
                  <label>Country</label>
                  <Select
                    menuPosition={"fixed"}
                    value={
                      formik.values?.country
                        ? countries
                            .map((o) => ({
                              label: o.name,
                              value: o.code3,
                            }))
                            .find((o) => o.value === formik.values?.country)
                        : null
                    }
                    styles={crispStyle}
                    options={countries.map((o) => ({
                      label: o.name,
                      value: o.code3,
                    }))}
                    onChange={(data) =>
                      formik.handleChange("country")(data.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="part-section">
        <h6>Account members</h6>
        <div className="part-section">
          <h6>Add new member</h6>
          <div className="row">
            <div className="col-md-12">
              <div className="part-section">
                <label>
                  You have reached the maximum amount of investors and
                  signatories for your account type. Any new members must be
                  non-signatory supporting members.
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="mb-2">
                <label>What is the supporting members role?</label>
                <Select
                  menuPosition={"fixed"}
                  value={
                    formik.values?.account_members?.supporting_member
                      ? supportingMemberOptions.find(
                          (o) =>
                            o.value ===
                            formik.values?.account_members?.supporting_member
                        )
                      : null
                  }
                  styles={crispStyle}
                  options={supportingMemberOptions}
                  onChange={(data) =>
                    formik.handleChange("account_members.supporting_member")(
                      data.value
                    )
                  }
                />
              </div>
            </div>
            <div className="col-md-12">
              <label>What is the person's name?</label>
              <div className="row">
                <div className="mb-2">
                  <label>First Name</label>
                  <Form.Control
                    type="text"
                    placeholder="First Name"
                    size="sm"
                    value={formik?.values?.account_members?.first_name}
                    name="account_members.first_name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="row">
                <div className="mb-2">
                  <label>Last Name</label>
                  <Form.Control
                    type="text"
                    placeholder="Last Name"
                    size="sm"
                    value={formik?.values?.account_members?.last_name}
                    name="account_members.last_name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <label>Is this a signatory member of your account?</label>
              <div className="row">
                <label>Signatory Status</label>
                <Select
                  menuPosition={"fixed"}
                  value={
                    formik.values?.account_members?.signatory_status
                      ? signatoryStatusOptions.find(
                          (o) =>
                            o.value ===
                            formik.values?.account_members?.signatory_status
                        )
                      : null
                  }
                  styles={crispStyle}
                  options={signatoryStatusOptions}
                  onChange={(data) =>
                    formik.handleChange("account_members.signatory_status")(
                      data.value
                    )
                  }
                />
              </div>
            </div>
            <div className="col-md-12">
              <label>Type in the members email below</label>
              <div className="row">
                <div className="mb-2">
                  <label>Email</label>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    size="sm"
                    value={formik.values?.account_members?.email}
                    name="account_members.email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="part-section">
        <h6>Account suitability</h6>
        <div className="row">
          <label>
            To better understand your Investing Account needs, please complete
            the following questionnaire. Required questions are marked with an
            asterisk (*)
          </label>
        </div>
        <br />
        <div className="row"></div>
        <div className="row">
          <div className="col-md-6">
            <h6>
              What are your goals for this Investing Account's real estate
              investments: (Check all that apply)? *
            </h6>
            {goalsRealEstateInvestmentsOptions.map((obj, i) => (
              <div key={`reg${i}`} className="check-list">
                <label>
                  <input
                    type="checkbox"
                    checked={formik.values?.account_suitability?.goal_for_investing_REI.includes(
                      obj.value
                    )}
                    onChange={(e) => handleGoalForInvestingREI(e, obj.value)}
                  />{" "}
                  {obj.label}
                </label>
              </div>
            ))}
          </div>
          <div className="col-md-6">
            <h6>
              Investments on the Marketplace generally have a hold period of 3-7
              years and are illiquid. Are you interested in making investments
              with hold periods over three years? *
            </h6>
            <Toggle
              className="mt-2"
              checked={
                formik.values.account_suitability.hold_periods_over_3_years
              }
              name="account_suitability.hold_periods_over_3_years"
              onChange={formik.handleChange}
            />
          </div>
          <div className="col-md-6">
            <h6>
              Are you interested in making aggressive and/or moderately
              aggressive investments? *
            </h6>

            <Toggle
              className="mt-2"
              checked={
                formik.values.account_suitability
                  .is_moderately_aggresive_investment
              }
              name="account_suitability.is_moderately_aggresive_investment"
              onChange={formik.handleChange}
            />
          </div>
          <div className="col-md-6">
            <h6>What is your approximate net worth? *</h6>
            {approximateNetWorthOptions.map((obj, i) => (
              <div key={`reg${i}`} className="mt-2">
                <label>
                  <input
                    type="radio"
                    checked={formik.values?.account_suitability?.approximate_net_worth?.includes(
                      obj.value
                    )}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "account_suitability.approximate_net_worth",
                        obj.value
                      )
                    }
                  />{" "}
                  {obj.label}
                </label>
              </div>
            ))}
          </div>
          <div className="col-md-6">
            <h6>General investing experience: (Check all that apply) </h6>
            {generalInvestingExperienceOptions.map((obj, i) => (
              <div key={`reg${i}`} className="check-list">
                <label>
                  <input
                    type="checkbox"
                    checked={formik.values?.account_suitability?.general_investing_experience.includes(
                      obj.value
                    )}
                    onChange={(e) =>
                      handleGeneralInvestingExperience(e, obj.value)
                    }
                  />{" "}
                  {obj.label}
                </label>
              </div>
            ))}
          </div>
          <div className="col-md-6">
            <h6>Commercial Real Estate (CRE) investing experience: </h6>
            {CREInvestingExperienceRadioOptions.map((obj, i) => (
              <div key={`reg${i}`} className="mt-2">
                <label>
                  <input
                    type="radio"
                    checked={formik.values?.account_suitability?.CRE_investing_experience_ratio?.includes(
                      obj.value
                    )}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "account_suitability.CRE_investing_experience_ratio",
                        obj.value
                      )
                    }
                  />{" "}
                  {obj.label}
                </label>
              </div>
            ))}
          </div>
          <div className="col-md-6">
            <h6>CRE investing experience details: (Check all that apply)</h6>
            {CREInvestingExperienceCheckOptions.map((obj, i) => (
              <div key={`reg${i}`} className="check-list">
                <label>
                  <input
                    type="checkbox"
                    checked={formik.values?.account_suitability?.CRE_investing_experience_check.includes(
                      obj.value
                    )}
                    onChange={(e) =>
                      handleCREInvestingExperienceCheck(e, obj.value)
                    }
                  />{" "}
                  {obj.label}
                </label>
              </div>
            ))}
          </div>
          <div className="col-md-6">
            <h6>Are you a qualified purchaser?</h6>
            <Toggle
              className="mt-2"
              checked={formik.values.account_suitability.is_qualified_purchaser}
              name="account_suitability.is_qualified_purchaser"
              onChange={formik.handleChange}
            />
          </div>
        </div>
      </div>
      <div className="part-section">
        <h6>Accreditation verification</h6>
        <DocumentUpload
          callback={(input) => {
            formik.setFieldValue("accreditation.accreditation_documents", [
              ...stateRef.current.accreditation.accreditation_documents,
              ...input.map((xxx) => ({
                document_id: xxx,
                is_approved: false,
                valid_upto: null,
              })),
            ]);
          }}
          documentType={documentTypeIds.investor}
          dependsTo={documentDependIds.investor}
        />
        {console.log(
          "xxx",
          formik.values.accreditation.accreditation_documents
        )}
        <DocumentList
          docs={formik.values.accreditation.accreditation_documents.map(
            (o) => o.document_id
          )}
          deleteCallback={(_data) => {
            formik.setFieldValue(
              "accreditation.accreditation_documents",
              formik.values.accreditation.accreditation_documents.filter(
                (xx) => xx.document_id !== _data
              )
            );
          }}
        />
      </div>
      <div className="part-section">
        <h6>Distribution details</h6>
        <div className="part-section">
          <h6>Preferred distribution method</h6>
          <div className="row">
            <div className="col-md-6">
              <Select
                menuPosition={"fixed"}
                value={
                  formik.values?.distribution_details
                    ?.preferred_distribution_method
                    ? preferredDistributionMethodOptions.find(
                        (o) =>
                          o.value ===
                          formik.values?.distribution_details
                            ?.preferred_distribution_method
                      )
                    : null
                }
                styles={crispStyle}
                options={preferredDistributionMethodOptions}
                onChange={(data) =>
                  formik.handleChange(
                    "distribution_details.preferred_distribution_method"
                  )(data.value)
                }
              />
            </div>
          </div>
        </div>
        <div className="part-section">
          <h6>Financial institution details</h6>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-2">
                <label>Financial instruction name</label>
                <Form.Control
                  type="text"
                  placeholder="Financial instruction name"
                  size="sm"
                  value={
                    formik.values.distribution_details
                      .financial_instruction_name
                  }
                  name="distribution_details.financial_instruction_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-2">
                <label>Routing number/ABA number</label>
                <Form.Control
                  type="text"
                  placeholder="Routing number/ABA number"
                  size="sm"
                  value={formik.values.distribution_details.routing_number}
                  name="distribution_details.routing_number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-2">
                <label>Financial instruction address</label>
                <Form.Control
                  type="text"
                  placeholder="Financial instruction address"
                  size="sm"
                  value={
                    formik.values.distribution_details
                      .financial_instruction_address
                  }
                  name="distribution_details.financial_instruction_address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-2">
                <label>Financial instruction phone number</label>
                <Form.Control
                  type="text"
                  placeholder="Financial instruction phone number"
                  size="sm"
                  value={
                    formik.values.distribution_details
                      .financial_instruction_phone_number
                  }
                  name="distribution_details.financial_instruction_phone_number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="part-section">
          <h6>Account type</h6>
          {accountTypesOptions.map((obj, i) => (
            <div key={`reg${i}`} className="mt-2">
              <label>
                <input
                  type="radio"
                  checked={formik.values?.distribution_details?.account_type.includes(
                    obj.value
                  )}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "distribution_details.account_type",
                      obj.value
                    )
                  }
                />{" "}
                {obj.label}
              </label>
            </div>
          ))}
        </div>
        <div className="part-section">
          <h6>Benificiary Details</h6>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-2">
                <label>Benificiary account name</label>
                <Form.Control
                  type="text"
                  placeholder="Benificiary account name"
                  size="sm"
                  value={
                    formik.values.distribution_details.beneficiary_account_name
                  }
                  name="distribution_details.beneficiary_account_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-2">
                <label>Benificiary account number</label>
                <Form.Control
                  type="text"
                  placeholder="Benificiary account number"
                  size="sm"
                  value={
                    formik.values.distribution_details
                      .beneficiary_account_number
                  }
                  name="distribution_details.beneficiary_account_number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="part-section">
        <h6>Account agreement</h6>
      </div>
    </form>
  );
};

export default InvestingAccounts;
