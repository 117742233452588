import React from "react";
import { useParams } from "react-router-dom";

import ContactsList from "./list";
import ContactForm from "./form";

import "./style.scss";

const Contacts = () => {
  const { id } = useParams();

  return <>{id ? <ContactForm /> : <ContactsList />}</>;
};

export default Contacts;
