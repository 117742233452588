import React from "react";
import { useParams } from "react-router-dom";

import PropertyList from "./list";
import PropertyForm from "./form";

import "./style.scss";

const Property = () => {
  const { id } = useParams();

  return <>{id ? <PropertyForm /> : <PropertyList />}</>;
};

export default Property;
