import React from "react";
import { useParams } from "react-router-dom";

import EmailList from "./list";
import EmailForm from "./form";

import "./style.scss";

const Email = () => {
  const { id } = useParams();

  return <>{id ? <EmailForm /> : <EmailList />}</>;
};

export default Email;
