import React from "react";
import { useSelector } from "react-redux";
import { FiMenu } from "react-icons/fi";

import "./style.scss";

const Header = () => {
  const user = useSelector((state) => state.user);

  const handleSideBarToggle = () => {
    if (document.body.classList.contains("side-bar-visible")) {
      document.body.classList.add("side-bar-invisible");
      document.body.classList.remove("side-bar-visible");
    } else {
      document.body.classList.add("side-bar-visible");
      document.body.classList.remove("side-bar-invisible");
    }
  };

  return (
    <div className="header-bar">
      <div className="d-flex align-items-center">
        <FiMenu
          size={30}
          className="side-button"
          role={"button"}
          onClick={handleSideBarToggle}
        />
        <span className="ms-2"> Alamo Fundings LLC</span>
      </div>
      <div className="d-flex align-items-center">
        <div className="fs-little">Hello, {user.data.name}</div>
        {/* <div className="ms-3 fs-little like-btn">
          <FiPower /> Logout
        </div> */}
      </div>
    </div>
  );
};

export default Header;
