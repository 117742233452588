import React from "react";
import { useParams } from "react-router-dom";

import ListingForm from "./form";
import ListingList from "./list";

import "./style.scss";

const Listings = () => {
  const { id } = useParams();
  return <>{id ? <ListingForm /> : <ListingList />}</>;
};

export default Listings;
