import React from "react";
import { useParams } from "react-router-dom";

import InvestmentList from "./list";
import InvestmentForm from "./form";

import "./style.scss";

const Investments = () => {
  const { id } = useParams();

  return <>{id ? <InvestmentForm /> : <InvestmentList />}</>;
};

export default Investments;
