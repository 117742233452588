import React from "react";

import "./style.scss";

const PageHeader = ({ id }) => {
  const page_info = [
    {
      id: "dashboard",
      title: "Dashboard",
    },
    { id: "properties", title: "Properties" },
    { id: "interest", title: "Interest" },
    { id: "listings", title: "Listings" },
    { id: "investments", title: "Investments" },
    { id: "websites", title: "Websites" },
    { id: "distributions", title: "Distributions" },
    { id: "documents", title: "Document" },
    { id: "investors", title: "Investors" },
    { id: "emails", title: "Sent Emails" },
    { id: "users", title: "Users" },
    { id: "contacts", title: "Contacts" },
    { id: "investing-accounts", title: "Investing Accounts" },
    { id: "contact-groups", title: "Contact Groups" },
  ];

  return (
    <div className="page-header">
      <div className="page-title">
        {page_info.find((o) => o.id === id)?.title}
      </div>
    </div>
  );
};

export default PageHeader;
