import React from "react";
import { useParams } from "react-router-dom";

import DistributionForm from "./form";
import DistributionList from "./list";

import "./style.scss";

const Distributions = () => {
  const { id } = useParams();
  return <>{id ? <DistributionForm /> : <DistributionList />}</>;
};

export default Distributions;
