import React, { useEffect, useRef } from "react";
import toast from "react-hot-toast";
import validator from "validator";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";

import InputPhone from "../input-phone";
import {
  createInvestment,
  getInvestment,
  updateInvestment,
} from "../../client/methods";
import { responseToastMsg } from "../../utils/response-message";
import { userSchema } from "../../utils/validation-schema";
import { FaArrowLeft, FaSave } from "react-icons/fa";

import "./style.scss";

const InvestmentForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const init = {
    investor: "",
    property: "",
    amount: "",
  };

  const stateRef = useRef();

  const onSubmit = async (values) => {
    const { investor, property, amount } = values;

    if (investor && property && amount) {
      try {
        if (id && id !== "new") {
          const result = await updateInvestment(id, values);

          responseToastMsg(result.data.response_code);
        } else {
          const result = await createInvestment(values);

          responseToastMsg(result.data.response_code);
        }
        navigate("/investments");
      } catch (error) {
        responseToastMsg(error.data.error_code);
        console.log(
          "🚀 ~ file: index.js ~ line 59 ~ handleSave ~ error",
          error
        );
      }
    } else {
      toast.error("Fill all the mandatory * fields");
    }
  };

  const formik = useFormik({
    initialValues: init,
    // validationSchema: userSchema,
    onSubmit,
  });

  stateRef.current = formik.values;

  useEffect(() => {
    if (id === "new") {
      formik.setValues(init);
    } else {
      id && handleGetUser(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleGetUser = async (input) => {
    try {
      const result = await getInvestment(input);

      formik.setValues({ ...result.data.response });
    } catch (error) {
      responseToastMsg(error.data.error_code);
      console.log(
        "🚀 ~ file: index.js ~ line 36 ~ handleGetUser ~ error",
        error
      );
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="pb-2 sticky-top mb-2 bg-white bottom-shadow">
          <Link
            to={"/investments"}
            className="btn btn-dark btn-sm ps-3 pe-3 me-2"
          >
            <FaArrowLeft /> Back to Investment List
          </Link>
          <button
            type="submit"
            className="btn btn-success btn-sm ps-3 pe-3 me-2"
          >
            <FaSave /> {id === "new" ? "Save" : "Update"}
          </button>
        </div>
        <div className="container-fluid">
          <h6> Investment info </h6>
          <div className="row">
            <div className="col-md-4">
              <div className="mb-2">
                <label>Investor Name</label>
                <Form.Control
                  type="text"
                  placeholder="Investor"
                  size="sm"
                  value={formik.values.investor}
                  name="investor"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-2">
                <label>Property*</label>
                <Form.Control
                  type="text"
                  placeholder="Property"
                  size="sm"
                  value={formik.values.property}
                  name="property"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-2">
                <label>Amount($)*</label>
                <Form.Control
                  type="text"
                  placeholder="Amount"
                  size="sm"
                  value={formik.values.amount}
                  name="amount"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default InvestmentForm;
