import React, { useEffect } from "react";
import { userLogoutThunk } from "../redux/thunk/user.thunk";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(userLogoutThunk());
    navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>Logging out...</>;
};

export default Logout;
