import React from "react";
import { useParams } from "react-router-dom";
import Dashboard from "../components/dashboard";
import Distributions from "../components/distributions";
import Documents from "../components/documents";
import Email from "../components/emails";
import Investments from "../components/investments";
import Page from "../components/page";
import Users from "../components/users";
import Investors from "../components/investors";
import Contacts from "../components/contacts";
import ContactGroups from "../components/contact-group";
import Property from "../components/property";
import Listings from "../components/listings";
import Interest from "../components/interest";
import Websites from "../components/website/index";

const Home = () => {
  const { page_id } = useParams();

  return (
    <>
      <Page>
        {(() => {
          switch (page_id) {
            case "dashboard":
              return <Dashboard />;
            case "properties":
              return <Property />;
            case "listings":
              return <Listings />;
            case "investments":
              return <Investments />;
            case "distributions":
              return <Distributions />;
            case "documents":
              return <Documents />;
            case "emails":
              return <Email />;
            case "investors":
              return <Investors />;
            case "users":
              return <Users />;
            case "interest":
              return <Interest />;
            case "contacts":
              return <Contacts />;
            case "websites":
              return <Websites />;
            case "contact-groups":
              return <ContactGroups />;

            default:
              return "404 - Not Found";
          }
        })()}
      </Page>
    </>
  );
};

export default Home;
