import React, { useEffect, useState, useRef } from "react";
import AsyncSelect from "react-select/async";
import toast from "react-hot-toast";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";

import ContactGroupList from "../group-contact-list";
import {
  createContactGroup,
  getContactGroup,
  getContactList,
  updateContactGroup,
} from "../../client/methods";
import { responseToastMsg } from "../../utils/response-message";

import { contactGroupSchema } from "../../utils/validation-schema";
import { FaArrowLeft, FaSave } from "react-icons/fa";
import { crispStyle } from "../../utils/common";

import "./style.scss";

const ContactGroupForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [contactList, setContactList] = useState([]);
  const [tempContact, setTempContact] = useState(null);

  const init = {
    name: "",
    contacts: [],
  };

  const stateRef = useRef();

  const loadContactList = async () => {
    try {
      const result = await getContactList(20, 0);
      setContactList([...result.data.response]);
    } catch (error) {
      responseToastMsg(error.data.error_code);

      console.log(
        "🚀 ~ file: index.js ~ line 18 ~ loadContactList ~ error",
        error
      );
    }
  };

  useEffect(() => {
    loadContactList();
  }, []);

  const loadContactListSearch = async (search = "") => {
    try {
      const result = await getContactList(20, 0, search);

      return [...result.data.response].map((o) => ({
        label: o.name,
        value: o._id,
      }));
    } catch (error) {
      responseToastMsg(error.data.error_code);
      console.log(
        "🚀 ~ file: index.js ~ line 34 ~ loadCustomerListSearch ~ error",
        error
      );
    }
  };

  const onSubmit = async (values) => {
    const { name } = values;

    if (name) {
      try {
        if (id && id !== "new") {
          const result = await updateContactGroup(id, values);

          responseToastMsg(result.data.response_code);
        } else {
          const result = await createContactGroup(values);

          responseToastMsg(result.data.response_code);
        }
        navigate("/contact-groups");
      } catch (error) {
        responseToastMsg(error.data.error_code);
        console.log(
          "🚀 ~ file: index.js ~ line 59 ~ handleSave ~ error",
          error
        );
      }
    } else {
      toast.error("Fill all the mandatory * fields");
    }
  };

  const formik = useFormik({
    initialValues: init,
    validationSchema: contactGroupSchema,
    onSubmit,
  });

  stateRef.current = formik.values;

  useEffect(() => {
    if (id === "new") {
      formik.setValues(init);
    } else {
      id && handleGetUser(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleGetUser = async (input) => {
    try {
      const result = await getContactGroup(input);

      formik.setValues({ ...result.data.response });
    } catch (error) {
      responseToastMsg(error.data.error_code);
      console.log(
        "🚀 ~ file: index.js ~ line 36 ~ handleGetUser ~ error",
        error
      );
    }
  };

  const handleAddContactToGroup = () => {
    if (!formik.values.contacts.includes(tempContact.value)) {
      formik.setFieldValue("contacts", [
        ...formik.values.contacts,
        tempContact.value,
      ]);
    } else {
      responseToastMsg("ER018");
    }

    setTempContact(null);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="pb-2 sticky-top mb-2 bg-white bottom-shadow">
          <Link
            to={"/contact-groups"}
            className="btn btn-dark btn-sm ps-3 pe-3 me-2"
          >
            <FaArrowLeft /> Back to Contact Group List
          </Link>
          <button
            type="submit"
            className="btn btn-success btn-sm ps-3 pe-3 me-2"
          >
            <FaSave /> {id === "new" ? "Save" : "Update"}
          </button>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4">
              <div className="mb-2">
                <label>Name*</label>
                <Form.Control
                  type="text"
                  placeholder="Group Name"
                  size="sm"
                  value={formik.values.name}
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={
                    formik.touched.name && formik.errors.name
                      ? "is-invalid"
                      : !formik.errors.name && "is-valid"
                  }
                />
                {formik.errors.name && formik.touched.name && (
                  <p className="text-danger">{formik.errors.name}</p>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="mb-2">
                <label>Search Contacts to add this group</label>
                <AsyncSelect
                  placeholder="Type to search customer..."
                  cacheOptions
                  styles={crispStyle}
                  value={tempContact}
                  defaultOptions={contactList.map((o) => ({
                    label: o.name,
                    value: o._id,
                  }))}
                  loadOptions={loadContactListSearch}
                  onChange={(input) => {
                    setTempContact(input);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="mb-2">
                <button
                  type="button"
                  className="btn btn-dark btn-sm"
                  onClick={handleAddContactToGroup}
                >
                  Add Contact to this Group
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="part-section">
                <h6> Group Contacts </h6>
                {formik.values.contacts.length > 0 && (
                  <ContactGroupList
                    contactsIds={formik.values.contacts}
                    handleContactFromGroup={(data) => {
                      formik.setFieldValue(
                        "contacts",
                        formik.values.contacts.filter((xx) => xx !== data)
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ContactGroupForm;
