import axios from "axios";
import createStore from "../redux/store";

import { removeCookies, getCookies } from "../utils/cookies";
import { userLogoutThunk } from "../redux/thunk/user.thunk";

axios.defaults.baseURL = process.env.REACT_APP_SERVER_API_URL;

axios.interceptors.request.use(
  function (config) {
    document.body.classList.add("loading-indicator");
    const auth_token = getCookies();

    if (auth_token) config.headers.Authorization = auth_token;
    const { user } = createStore.getState();

    if (user?.data) {
      config.headers["x-user"] = user?.data?.user_id;
    }
    return config;
  },
  function (error) {
    document.body.classList.remove("loading-indicator");
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    document.body.classList.remove("loading-indicator");
    return response;
  },
  (error) => {
    document.body.classList.remove("loading-indicator");
    if (error?.response?.status === 401) {
      removeCookies();
      createStore.dispatch(userLogoutThunk());
      if (
        !window.location.href.includes("signin?redirect") &&
        !window.location.href.includes("/password")
      ) {
        window.open("/", "_self");
      }
    }
    return Promise.reject(error?.response);
  }
);

export default axios;
